<template>
  <div
    v-if="formattedChatData.formattedChatLog.length >= 0"
    class="chats"
  >
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId + String(index)"
      class="chat"
      :class="{ 'chat-left': msgGrp.type === 'in' }"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
        />
      </div>
      <div class="chat-body">
        <div
          v-for="(msgData, indexMsg) in msgGrp.messages"
          :key="indexMsg"
          class="chat-content"
        >
          <p v-if="msgData.typeMsg === 'text'">
            {{ msgData.msg }}
          </p>
          <p v-if="msgData.typeMsg !== 'text'">
            {{ putLabel(msgData) }}
            <b-button
              v-b-modal.modal-1
              variant="primary"
              @click="openModal(msgData)"
            >
              {{ replaceTypeIcons(msgData.typeMsg) }}
            </b-button>
            {{ putCaption(msgData) }}
          </p>
          <span style="font-size: 8px"> {{ msgData.time }} </span>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-1"
      title="Visualizador"
      ok-only
      ok-title="Cerrar"
      centered
      :size="typeImg !== 'audio' && typeImg !== 'document' ? 'sm':'sm'"
    >
      <b-overlay
        :show="loading"
        rounded="sm"
        style="height: 100%;"
      >
        <div
          v-if="base64 && typeImg"
          style="display:flex;justify-content:center;"
        >
          <b-img
            v-if="typeImg === 'image'"
            :src="whTypeMedia+base64"
            style="height:450px;width:100%;object-fit: contain;"
          />

          <audio
            v-if="typeImg === 'audio'"
            controls
            autoplay
          >
            <source
              :src="whTypeMedia+base64"
            >
            Your browser does not support the audio element.
          </audio>

          <video
            v-if="typeImg === 'video'"
            controls=""
            :src="whTypeMedia+base64"
            style="
              height: 450px;
              width: 100%;
            "
          />

          <a
            v-if="typeImg === 'document'"
            :href="whTypeMedia+base64"
            target="_blank"
            :download="typeImg"
          >
            <b-button

              variant="primary"
            >
              Descargar
            </b-button>
          </a>

        </div>
      </b-overlay>
    </b-modal>
  </div>

</template>

<script>
import { computed, ref } from '@vue/composition-api'
import axios from 'axios'
import {
  BAvatar, BModal, BButton, VBModal, BImg, BOverlay,
} from 'bootstrap-vue'
import { urlApiServices } from '../../../getUrls'

export default {
  components: {
    BAvatar,
    BButton,
    BModal,
    VBModal,
    BImg,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
    },
  },
  setup(props) {
    const base64 = ref('')
    const typeImg = ref('')
    const whTypeMedia = ref('')
    const loading = ref(false)

    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.contact.id,
        avatar: props.chatData.contact.avatar,
      }

      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat.chat
      }
      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].type : undefined
      let msgGroup = {
        type: chatMessageSenderId,
        messages: [],
      }
      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.type) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg.time,
            typeMsg: msg.typeMsg,
          })
        } else {
          chatMessageSenderId = msg.type
          formattedChatLog.push(msgGroup)
          msgGroup = {
            type: msg.type,
            messages: [{
              msg: msg.message,
              time: msg.time,
              typeMsg: msg.typeMsg,
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })
      return {
        formattedChatLog,
        contact,
      }
    })

    const replaceType = type => {
      switch (type) {
        case 'image':
          return 'Imagen:'
        case 'document':
          return 'Documento:'
        case 'audio':
          return 'Audio:'
        case 'video':
          return 'Video:'
        default:
          return 'Imagen:'
      }
    }

    const chargeMedia = async id => {
      try {
        loading.value = true
        const response = await axios.get(`${urlApiServices}/media-whatsapp-base64`, {
          params: { mediaId: id },
        })
        if (response.status === 200) {
          if (response.data.success) {
            base64.value = response.data.data.base64
            whTypeMedia.value = `data:${response.data.data.mime_type};base64,`
          } else {
            base64.value = ''
            typeImg.value = ''
          }
        } else {
          base64.value = ''
          typeImg.value = ''
        }
        loading.value = false
      } catch (error) {
        base64.value = ''
        typeImg.value = ''
        loading.value = false
      }
    }

    const replaceTypeIcons = type => {
      switch (type) {
        case 'image':
          return 'Imagen 🏙'
        case 'document':
          return 'Documento 📄'
        case 'audio':
          return 'Audio 🎤'
        case 'video':
          return 'Video 📹'
        default:
          return 'Imagen 🏙'
      }
    }

    const putLabel = msg => {
      let label = ''
      if (msg.msg.includes('Nombre del archivo:')) {
        label = msg.msg.slice('N', msg.msg.indexOf(replaceType(msg.typeMsg)))
        label = `${label}`
      }
      return label
    }
    const putCaption = msg => {
      let caption = ''
      if (msg.msg.includes('Mensaje:')) {
        caption = msg.msg.slice(msg.msg.indexOf('Mensaje:'), msg.msg.length)
        caption = `\n\n${caption}`
      }
      return caption
    }

    const openModal = msg => {
      const id = msg.msg.split('<$')[1].split('$>')[0]
      typeImg.value = msg.typeMsg
      chargeMedia(id)
      return id
    }

    return {
      formattedChatData,
      replaceType,
      putLabel,
      putCaption,
      replaceTypeIcons,
      openModal,
      base64,
      typeImg,
      loading,
      whTypeMedia,
    }
  },
}
</script>

<style></style>
