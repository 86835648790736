<template lang="html">
    <div class="centerx icons-example">
  
      <form>
        
            <!-- basic -->
            <b-form-group
              label="Nombres"
              label-for="firstname"
            >
              <b-form-input
                id="firstname"
                v-model="contactInfo.firstname"
                :disabled="isContactInfo"
                placeholder="Nombres"
              />
            </b-form-group>
          
  
            <!-- basic -->
            <b-form-group
              label="Apellidos"
              label-for="lastname"
            >
              <b-form-input
                id="lastname"
                v-model="contactInfo.lastname"
                :disabled="isContactInfo"
                placeholder="Apellidos"
              />
            </b-form-group>
          
  
            <!-- basic -->
            <b-form-group
              label="Telefono Movil"
              label-for="mobile_number"
            >
              <b-form-input
                id="mobile_number"
                v-model="contactInfo.mobile_number"
                :disabled="isContactInfo"
                placeholder="Telefono Movil"
              />
            </b-form-group>
          
            <!-- basic -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="contactInfo.email"
                :disabled="isContactInfo"
                placeholder="Email"
              />
            </b-form-group>
          
  
            <!-- basic -->
            <b-form-group
              label="Tipo de identificación"
              label-for="identification_type_id"
            >
              <b-form-select
                id="identification_type_id"
                v-model="contactInfo.identification_type_id"
                :disabled="isContactInfo"
                :options="identificationTypes"
              />
            </b-form-group>
          
  
            <!-- basic -->
            <b-form-group
              label="Identificación"
              label-for="identification"
            >
              <b-form-input
                id="identification"
                v-model="contactInfo.identification"
                :disabled="isContactInfo"
                placeholder="Identificación"
              />
            </b-form-group>
          
          
            <!-- button on right -->
            <b-input-group>
              <b-form-group
                style="width: 80%;"
              >
                <b-form-input
                  v-if="istSearchAccounts"
                  id="searchAccount"
                  v-model="account"
                  :disabled="isContactInfo"
                  placeholder="Busqueda de Compañia"
                />
                <b-form-select
                  v-if="!istSearchAccounts"
                  id="setAccount"
                  v-model="account_idLocal"
                  :disabled="isContactInfo"
                  placeholder="Seleccione la compañia"
                  :options="accounts"
                />
              </b-form-group>
              <b-input-group-append
                style="
                  height: 38px;
                  width: 20%;
              "
              >
                <b-button
                  v-if="istSearchAccounts"
                  variant="outline-primary"
                  :disabled="isContactInfo"
                  style="
                    padding: 4%;
                    width: 100%;
                "
                  @click="searchAccounts(true)"
                >
                  <feather-icon
                    icon="SearchIcon"
                  />
                </b-button>
                <b-button
                  v-if="!istSearchAccounts"
                  variant="outline-primary"
                  :disabled="isContactInfo"
                  style="
                    padding: 4%;
                    width: 100%;
                "
                  @click="searchAccounts(false)"
                >
                  <feather-icon
                    icon="RefreshCwIcon"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              style="width: 45%; margin-bottom: 3%; margin-left: 8%"
              size="sm"
              @click="$emit('show-contact-form')" 
              class="mr-1"
            >
              {{ "Crear Cuenta" }}
            </b-button>
  
            <!-- basic -->
            <b-form-group
              label="Departamento"
              label-for="state"
            >
              <b-form-select
                id="state"
                v-model="contactInfo.state"
                :disabled="isContactInfo"
                :options="stateCodes"
                @change="filterCity"
              />
            </b-form-group>
          
  
            <!-- basic -->
            <b-form-group
              label="Ciudad"
              label-for="city"
            >
              <b-form-select
                id="city"
                v-model="contactInfo.city"
                :disabled="isContactInfo"
                :options="citys || cityCodes"
              />
            </b-form-group>
          
  
        <!--Start: Form Actions-->
            <div
              class="mt-10"
              style="display:flex;justify-content: center;"
            >
              <b-button
                v-if=isFormAddData
                variant="primary"
                style="width: 90%;margin-top: 3%;"
                @click="createContact()"
              >
                Crear
              </b-button>
            </div>
          
      <!--End: Form Actions-->
  
      </form>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import {
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BButton,
    BFormSelect,
    BBadge,
  } from 'bootstrap-vue'
  import { cityCodes } from '../../codesData/cityCodes'
  import { stateCodes } from '../../codesData/stateCodes'
  import { identificationTypes } from '../../codesData/identificationTypes'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  
  const dict = {
    custom: {
      first_name: {
        required: 'Please enter the first name',
      },
      last_name: {
        required: 'Please enter the last name',
      },
      emailValue: {
        email: 'The field must to be a email',
      },
      general: {
        required: 'Required',
      },
    },
  }
  
  export default {
    components: {
      BCol,
      BFormGroup,
      BFormInput,
      BInputGroupAppend,
      BInputGroup,
      BButton,
      BFormSelect,
      BBadge,
  
    },
    props: {
  
    },
    data() {
      const data = {
        cityCodes,
        stateCodes,
        identificationTypes,
        account: '',
        citys: [],
        filteredOptions: [],
        account_idLocal: '',
  
      }
  
      return data
    },
  
    async mounted() {
      this.citys = this.filterCity()
      this.account_idLocal = this.contactInfo.account_id
    },
    computed: {
      ...mapState({
        contactInfo: state => state.contact.contactInfo,
        isContactInfo: state => state.isContactInfo,
        isFormAddData: state => state.isFormAddData,
        accounts: state => state.contact.accounts,
        istSearchAccounts: state => state.contact.istSearchAccounts,
        mobile: state => state.contact.contactInfo.mobile_number,
        account_id: state => state.contact.contactInfo.account_id,
      }),
    },
    watch: {
      mobile() {
        this.citys = this.filterCity()
      },
      citys() {
        if (this.citys && this.citys.length > 0) {
          const validate = this.citys.filter(city => this.contactInfo.city === city.value)
          if (validate.length === 0) {
            if (this.citys && this.citys[0] && this.citys[0].value) {
              this.contactInfo.city = this.citys[0].value
            }
          }
        } else {
          this.contactInfo.city = this.cityCodes[0].value
        }
      },
      account_id() {
        if (this.account_id) {
          const payload = {
            query: this.contactInfo.account.name,
            toast: this.$toast,
            isTicket: false,
          }
          this.$store.dispatch('contact/searchAccounts', payload)
          this.account = ''
          this.account_idLocal = this.account_id
        } else {
          const payload = {
            isTicket: false,
            isSearch: true,
          }
          this.$store.dispatch('contact/setItsSearchAccounts', payload)
          this.account = ''
          this.account_idLocal = ''
        }
      },
    },
    methods: {
      createContact() {
        if (!this.contactInfo.email || !this.contactInfo.firstname || !this.contactInfo.lastname
        || !this.contactInfo.identification || !this.contactInfo.mobile_number) {
          this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Faltan campos",
                icon: "CoffeeIcon",
                variant: "danger",
                text: "Debe completar todos los campos requeridos",
              }
            })
          return
        }
        const data = {
          firstname: this.contactInfo.firstname,
          lastname: this.contactInfo.lastname,
          identification_type_id: this.contactInfo.identification_type_id,
          identification: this.contactInfo.identification,
          country: 'COL',
          state: this.contactInfo.state,
          city: this.contactInfo.city,
          account_id: this.account_idLocal,
          mobile_number: this.contactInfo.mobile_number,
          email: this.contactInfo.email,
        }
        const payload = {
          contactDetails: {
            ...data,
          },
          toast: this.$toast,
  
        }
        this.$store.dispatch('contact/createContact', payload)
        this.$emit('show-contact-form')
      },
      searchAccounts(bool) {
        if (bool) {
          if (!this.account) {
            return
          }
          const payload = {
            query: this.account,
            toast: this.$toast,
            isTicket: false,
          }
          this.$store.dispatch('contact/searchAccounts', payload)
          this.account = ''
          this.account_idLocal = ''
        } else {
          const payloadSearch = {
            isTicket: false,
            isSearch: true,
          }
          const payload = {
            isTicket: false,
            value: [],
          }
          this.$store.dispatch('contact/setItsSearchAccounts', payloadSearch)
          this.$store.dispatch('contact/setAccounts', payload)
        }
      },
      filterCity() {
        if (this.contactInfo.state && this.contactInfo.city) {
          const citys = this.cityCodes.filter(city => this.contactInfo.state === city['Código Departamento'])
          this.citys = citys
        } else {
          const citys = this.cityCodes.filter(city => city['Código Departamento'] === '05')
          this.citys = citys
        }
      },
      onSelected(option) {
        console.log(option)
        this.contactInfo.account_id = option
      },
      onInputChange(text) {
        if (text === '' || text === undefined) {
          return
        }
  
        this.account = text
        console.log(this.contactInfo)
        const filteredData = this.accounts.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, 10)
        this.filteredOptions = [{
          data: filteredData,
        }]
      },
    },
  }
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  