<template lang="html">
  <div>
    <h2>Tickets</h2>
      <b-badge
        href="#"
        @click = "$emit('close-ticket-list')"
        variant="primary"
        class="mr-2 mb-2"
      >
        <feather-icon
          icon="ArrowLeftIcon"
        />
      </b-badge>
    <b-overlay 
    :show="loading"
    variant="white"
    opacity= 0.9
    rounded="sm"
    >
        <b-row>
            <b-col cols="12" v-for="ticket in tickets" :key="ticket.description">
              <b-card-actions
                :title=formatSuportTypes(ticket.support_type_id)
                action-collapse
                :collapsed= colapsed
              >
                <b-card-text>
                  <span>{{ticket.description}}</span>
                </b-card-text>
                <b-card-text>
                  <span>{{formatTypes(ticket.ticket_type_id)}}</span>
                </b-card-text>
                <b-card-text>
                  <span>{{formatTypes(ticket.agent_id)}}</span>
                </b-card-text>
              </b-card-actions>
            </b-col>
        </b-row>
    </b-overlay>
    <b-overlay 
    :show= "moreLoading"
    variant="white"
    opacity= 0.9
    rounded="sm">
      <b-button
        v-if = "moreTickets"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="flat-primary"
        @click="getMoreTickets()"
      >
        Ver más
    </b-button>
    </b-overlay>

  </div>
</template>

<script>
import { mapState } from "vuex";
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BOverlay, BCard, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'TicketsVue',
  components: {
    BCardActions,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BOverlay,
    BCard,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {},
  data() {
    const data = {
      totalRows: 1,
      currentPage: 1,
      items: [],
      loading: true,
      colapsed: true,
      moreLoading: false
    };

    return data
  },

  mounted() {
    const payload = {
      mobile_number: this.mobile,
      page : 1,
      animation: this.closeLoading,
      toast: this.$toast,
    }
    this.$store.dispatch('ticket/getTickets', payload)
    
  },
  computed: {
    ...mapState({
      supportTypes: state => state.ticket.supportTypes,
      ticketTypes: state => state.ticket.ticketTypes,
      contact_id: state => state.contact.contactInfo.contact_id,
      mobile: state => state.contact.contactInfo.mobile_number,
      tickets: state => state.ticket.tickets,
      pages: state => state.ticket.ticketsPages,
      moreTickets: state => state.ticket.moreTickets
    }),
  },
  methods: {
    getMoreTickets(){
      this.closeMoreLoading()
      const payload = {
        mobile_number: this.mobile,
        page : this.pages,
        animation: this.closeMoreLoading,
        toast: this.$toast,
      }
      this.$store.dispatch('ticket/getTickets', payload)
    },
    closeLoading(){
      this.loading = this.loading ? false : true
    },
    closeMoreLoading(){
      this.moreLoading = this.moreLoading? false : true
    },
    formatTypes(id){
      for(var i = 0; i < this.ticketTypes.length; i++){
        if(this.ticketTypes[i].value == id){
          return this.ticketTypes[i].text
        }
      }
    },
    formatSuportTypes(id){
      for(var i = 0; i < this.supportTypes.length; i++){
        if(this.supportTypes[i].value == id){
          return this.supportTypes[i].text
        }
      }
    },
  }
};
</script>
<style lang="scss">
</style>
