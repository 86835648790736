<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div class="content-wrapper">
    <b-overlay
      :show="loading"
      rounded="sm"
      style="height: 100%"
    >
      <div
        class="body-content-overlay"
        :class="{
          show:
            shallShowUserProfileSidebar ||
            shallShowActiveChatContactSidebar ||
            mqShallShowLeftSidebar,
        }"
        @click="
          mqShallShowLeftSidebar =
            shallShowActiveChatContactSidebar =
            shallShowUserProfileSidebar =
            false
        "
      />

      <!-- Main Area -->
      <section class="row chat-app-window">
        <!-- Start Chat Logo -->

        <div
          v-if="!activeChat.contact"
          class="start-chat-area"
        >
          <div class="mb-1 start-chat-icon">
            <feather-icon
              icon="MessageSquareIcon"
              size="56"
            />
          </div>
          <h4
            class="sidebar-toggle start-chat-text"
            @click="startConversation"
          >
            Comienza una conversación
          </h4>
        </div>

        <!-- Chat Content -->
        <div
          v-else
          class="active-chat"
        >
          <!-- Chat Navbar -->
          <div class="chat-navbar">
            <header class="chat-header">
              <!-- Avatar & Name -->
              <div class="d-flex align-items-center">
                <!-- Toggle Icon -->
                <div class="sidebar-toggle d-block d-lg-none mr-1">
                  <feather-icon
                    icon="MenuIcon"
                    class="cursor-pointer"
                    size="21"
                    @click="mqShallShowLeftSidebar = true"
                  />
                </div>

                <b-avatar
                  size="36"
                  :src="activeChat.contact.avatar"
                  class="mr-1 cursor-pointer badge-minimal"
                  badge
                  :badge-variant="
                    resolveAvatarBadgeVariant(activeChat.contact.status)
                  "
                  @click.native="shallShowActiveChatContactSidebar = false"
                />
                <h6 class="mb-0">
                  {{ activeChat.contact.fullName }}
                </h6>
              </div>

              <!-- Contact Actions -->
              <div
                class="d-flex align-items-center"
                style="width: 25%; display: flex; justify-content: space-evenly"
              >
                <b-button
                  variant="primary"
                  @click="confirmClose()"
                >
                  Finalizar Chat</b-button>
                <!--
                <div class="dropdown">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="17"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item>
                      View Contact
                    </b-dropdown-item>
                    <b-dropdown-item>
                      Mute Notifications
                    </b-dropdown-item>
                    <b-dropdown-item>
                      Block Contact
                    </b-dropdown-item>
                    <b-dropdown-item>
                      Clear Chat
                    </b-dropdown-item>
                    <b-dropdown-item>
                      Report
                    </b-dropdown-item>
                  </b-dropdown>
                </div>-->
              </div>
            </header>
          </div>

          <!-- User Chat Area -->
          <vue-perfect-scrollbar
            ref="refChatLogPS"
            :settings="perfectScrollbarSettings"
            class="user-chats scroll-area"
          >
            <chat-log
              :chat-data="activeChat"
            />
          </vue-perfect-scrollbar>

          <!-- Message Input -->
          <b-form
            class="chat-app-form"
            @submit.prevent="sendMessage"
          >
            <b-input-group class="input-group-merge form-send-message mr-1">
              <b-form-input
                v-model="chatInputMessage"
                placeholder="Enter your message"
              />
            </b-input-group>
            <div
              style="
                display: flex;
                gap: 3%;
                justify-content: center;
              "
            >
              <b-button
                v-b-modal.modal-2
                variant="primary"
                style="padding: 1%;width: 45%;"
              >
                <feather-icon
                  icon="PaperclipIcon"
                  size="22"
                />
              </b-button>
              <b-button
                variant="primary"
                type="submit"
                style="
                      width: 55%;
                      display: flex;
                      justify-content: center;
                    "
              >
                Enviar
              </b-button>
            </div>

          </b-form>
        </div>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="time-change scroll-area"
        >
          <TimeToChangeRightbar v-if="timeToChangeStatus" />
          <welcome v-else />
        </vue-perfect-scrollbar>
      </section>



      <!-- Active Chat Contact Details Sidebar -->
      <chat-active-chat-content-details-sidedbar
        :shall-show-active-chat-contact-sidebar.sync="
          shallShowActiveChatContactSidebar
        "
        :contact="activeChat.contact || {}"
      />

      <!-- Sidebar -->
      <portal to="content-renderer-sidebar-left">
        <b-overlay
          :show="loading"
          rounded="sm"
          style="height: 100%;"
        >
          <chat-left-sidebar
            style="height: 100%"
            :chats-contacts="activeContacts"
            :contacts="pendingContacts"
            :active-chat-contact-id="
              activeChat.contact ? activeChat.contact.id : null
            "
            :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
            :profile-user-data="profileUserData"
            :profile-user-minimal-data="profileUserDataMinimal"
            :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
            :web-socket="socket"
            @show-user-profile="showUserProfileSidebar"
            @open-chat="openChatOfContact"
          />
        </b-overlay>
      </portal>
    </b-overlay>
    <b-modal
      id="modal-2"
      title="Selecciona el archivo"
      hide-footer
      centered
      :size="'sm'"
    >
      <b-overlay
        :show="loadingModal"
        rounded="sm"
        style="height: 100%;"
      >
        <div
          style="display:flex;justify-content:space-around;flex-direction:column;height: 150px;"
        >
          <b-form-file
            id="file"
            v-model="file"
            placeholder="Seleccione su archivo"
            drop-placeholder="Suelte su archivo aqui..."
            :accept="Accept"
          />
          <div
            style="
              display: flex;
              justify-content: flex-end;
              gap: 8%;
          "
          >
            <b-button
              style="width:30%;"
              variant="primary"
              :disabled="loading"
              @click="senMediaMsgButton()"
            >
              Enviar
            </b-button>
            <b-button
              style="width:30%;"
              variant="primary"
              @click="$bvModal.hide('modal-2')"
            >
              Cerrar
            </b-button>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
import { ref, onUnmounted, nextTick } from '@vue/composition-api'
import {
  BAvatar,
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
  VBTooltip,
  BOverlay,
  VBModal,
  BModal,
  BFormFile,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import axios from 'axios'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import chatStoreModule from './chatStoreModule'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'
import TimeToChangeRightbar from '../../time-to-change/TimeToChangeRightbar.vue'
import Welcome from '../../time-to-change/Welcome.vue'
import { urlWebSocket, urlApiServices } from '../../../getUrls'

export default {
  components: {
    TimeToChangeRightbar,
    Welcome,
    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BOverlay,
    BModal,
    VBModal,
    BFormFile,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  setup() {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'
    const URL = urlApiServices
    const Accept = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,image/*, application/pdf,text/plain,.xlsx, .xls, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .docx'
    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) {
      store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
      }
    })
    const loading = ref(false)
    const loadingModal = ref(false)
    const { resolveAvatarBadgeVariant } = useChat()
    const file = ref(null)
    const allChats = ref([])
    const socket = ref(null)
    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    const insertChat = data => {
      const chat = {
        sesionId: data.sesionId,
        phoneNumber: data.phoneNumber,
        chatMsgs: [...data.info],
      }
      allChats.value.push(chat)
    }

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const activeContacts = ref([])
    const pendingContacts = ref([])

    const searchPosition = (id, type) => {
      let position = -1
      if (type === 'chat' && allChats.value.length > 0) {
        position = allChats.value.findIndex(pos => pos.sesionId === id)
      } else if (type === 'active' && activeContacts.value.length > 0) {
        position = activeContacts.value.findIndex(
          contact => contact.id === id,
        )
      } else if (type === 'pending' && pendingContacts.value.length > 0) {
        position = pendingContacts.value.findIndex(
          contact => contact.id === id,
        )
      }
      return position
    }

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')
    const getActiveOrPendingContact = userId => {
      const isActiveContact = searchPosition(userId, 'active')
      const isPendingContact = searchPosition(userId, 'pending')
      const info = { contact: {}, type: '' }
      if (isActiveContact >= 0) {
        info.contact = activeContacts.value[isActiveContact]
        info.type = 'active'
      } else if (isPendingContact >= 0) {
        info.contact = pendingContacts.value[isPendingContact]
        info.type = 'pending'
      }
      return info
    }
    const openChatOfContact = userId => {
      store.dispatch('timeToChangeOverlay', true)
      const { contact, type } = getActiveOrPendingContact(userId)
      chatInputMessage.value = ''
      const response = {
        chat: {
          chat: allChats.value[searchPosition(userId, 'chat')].chatMsgs,
          id: userId,
          unseenMsgs: 0,
          userId,
        },
        contact,
        type,
      }
      if (type === 'active') {
        activeContacts.value[
          searchPosition(userId, 'active')
        ].chat.unseenMsgs = 0
      } else {
        pendingContacts.value[
          searchPosition(userId, 'pending')
        ].chat.unseenMsgs = 0
      }

      activeChat.value = response

      // Scroll to bottom
      nextTick(() => {
        scrollToBottomInChatLog()
      })
      store.dispatch('whatsAppContactInfoFromServer', {
        mobileOpt1: activeChat.value.contact.fullName,
      })

      store.dispatch('timeToChage', true)
      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }
    const changeContactStatus = position => {
      const contact = pendingContacts.value[position]
      activeChat.value.type = 'active'
      pendingContacts.value.splice(position, 1)
      activeContacts.value.push({ ...contact, status: 'online' })
    }
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})

    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      store.dispatch('app-chat/getProfileUser').then(response => {
        profileUserData.value = response.data
        shallShowUserProfileSidebar.value = true
      })
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    const addPendingContacts = data => {
      const allMsgs = [{
        message: '', time: new Date(), type: 'in', typeMsg: data?.type || 'text',
      }]
      allMsgs[0].message = data.info
      const newData = {
        sesionId: data.sesionId,
        phoneNumber: data.phoneNumber,
        info: allMsgs,
      }
      let dataCut = allMsgs[0].message
      if (allMsgs[0].message.length > 21) {
        dataCut = `${allMsgs[0].message.slice(0, 21)}...`
      }

      pendingContacts.value.push({
        id: data.sesionId,
        fullName: data.phoneNumber,
        avatar: '../icons/logoEpika-128X128.png',
        about: dataCut,
        role: 'Frontend Developer',
        status: 'offline',
        chat: {
          id: data.sesionId,
          unseenMsgs: 1,
          lastMessage: {
            message: dataCut,
            senderId: 'in',
            time: new Date(),
          },
        },
      })
      insertChat(newData)
    }
    const changeLastMessage = (type, data, addCount = true) => {
      let dataCut = data.info
      if (data.info.length > 21) {
        dataCut = `${data.info.slice(0, 21)}...`
      }
      if (type === 'active') {
        const unSeenMsg = addCount
          ? activeContacts.value[searchPosition(data.sesionId, 'active')]?.chat
            ?.unseenMsgs + 1
          : 0
        const contact = {
          ...activeContacts.value[searchPosition(data.sesionId, 'active')],
          about: dataCut,
          chat: {
            ...activeContacts.value[searchPosition(data.sesionId, 'active')]
              .chat,
            unseenMsgs: unSeenMsg,
            lastMessage: {
              message: dataCut,
              senderId: 'in',
              time: new Date(),
            },
          },
        }
        activeContacts.value.splice(searchPosition(data.sesionId, 'active'), 1)
        activeContacts.value.unshift(contact)
      } else {
        const unSeenMsg = addCount
          ? pendingContacts.value[searchPosition(data.sesionId, 'pending')]
            ?.chat?.unseenMsgs + 1
          : 0
        const contact = {
          ...pendingContacts.value[searchPosition(data.sesionId, 'pending')],
          about: dataCut,
          chat: {
            ...pendingContacts.value[searchPosition(data.sesionId, 'pending')]
              .chat,
            unseenMsgs: unSeenMsg,
            lastMessage: {
              message: dataCut,
              senderId: 'in',
              time: new Date(),
            },
          },
        }
        pendingContacts.value.splice(
          searchPosition(data.sesionId, 'pending'),
          1,
        )
        pendingContacts.value.unshift(contact)
      }
    }

    const addMsgToAllChats = (pos, data, type) => {
      allChats.value[pos].chatMsgs.push({
        message: data.info,
        time: new Date(),
        type,
        typeMsg: data.type,
      })
    }

    const sendMessage = () => {
      if (!chatInputMessage.value) return
      const payload = {
        time: new Date(),
        // eslint-disable-next-line no-use-before-define
        type: 'out',
        message: chatInputMessage.value,
        typeMsg: 'text',
      }
      const positionActiveChat = searchPosition(
        activeChat.value.chat.id,
        'chat',
      )
      if (activeChat.value.type === 'pending') {
        const positionPendingChat = searchPosition(
          activeChat.value.chat.id,
          'pending',
        )
        changeContactStatus(positionPendingChat)
      }
      allChats.value[positionActiveChat].chatMsgs.push(payload)

      const msg = {
        // eslint-disable-next-line quote-props
        action: 'sendmessage',
        msg: chatInputMessage.value,
        sessionId: activeChat.value.chat.id,
        type: 'text',
      }
      const lastsMessageInfo = {
        sesionId: activeChat.value.chat.id,
        info: chatInputMessage.value,
      }
      changeLastMessage('active', lastsMessageInfo, false)
      try {
        socket.value.send(JSON.stringify(msg))
        chatInputMessage.value = ''
      } catch (err) {
        console.log(err)
      }
      nextTick(() => {
        scrollToBottomInChatLog()
      })
    }

    const closeSession = async () => {
      const sessionId = activeChat.value.contact.id
      loading.value = true
      try {
        const response = await axios.put(`${URL}/close-session`, {
          sessionId,
        })
        if (response.status === 200) {
          store.dispatch('timeToChage', false)
          const { type } = getActiveOrPendingContact(sessionId)
          if (type === 'active') {
            activeContacts.value = activeContacts.value.filter(
              contact => contact.id !== sessionId,
            )
            activeChat.value = {}
          } else {
            pendingContacts.value = pendingContacts.value.filter(
              contact => contact.id !== sessionId,
            )
            activeChat.value = {}
          }
        }
        loading.value = false
      } catch (error) {
        console.log(error)
        loading.value = false
      }
    }
    const resetInitState = () => {
      activeChat.value = {}
      activeContacts.value = []
      pendingContacts.value = []
    }
    const getAllSessions = async email => {
      try {
        loading.value = true
        const response = await axios.get(`${URL}/agent-sessions`, {
          params: { agentId: email },
        })
        if (response.status === 200) {
          if (response.data.length > 0) {
            resetInitState()
            const sessions = response.data
            sessions.forEach(session => {
              let dataCut = ''
              const chat = {
                sesionId: session.sesionId,
                phoneNumber: session.phoneNumber,
                info: [],
              }
              if (session.chat.length > 0) {
                dataCut = session.chat[session.chat.length - 1].info
                if (session.chat[0].info.length > 21) {
                  dataCut = `${session.chat[session.chat.length - 1].info.slice(
                    0,
                    21,
                  )}...`
                }
                session.chat.forEach(msg => {
                  const msgFormat = {
                    message: msg.info,
                    time: new Date(msg.time),
                    type: msg.type,
                    typeMsg: msg.typeMsg,
                  }
                  chat.info.push(msgFormat)
                })
              }
              insertChat(chat)
              const sessionFormat = {
                id: session.sesionId,
                fullName: session.phoneNumber,
                avatar: '../icons/logoEpika-128X128.png',
                about: dataCut,
                role: 'Frontend Developer',
                status: 'offline',
                chat: {
                  id: session.sesionId,
                  unseenMsgs: 0,
                  lastMessage: {
                    message: dataCut,
                    senderId: 'in',
                    time: new Date(),
                  },
                },
              }
              if (session.pending) {
                pendingContacts.value.push(sessionFormat)
              } else {
                activeContacts.value.push({
                  ...sessionFormat,
                  status: 'online',
                })
              }
            })
          }
        }
        loading.value = false
      } catch (err) {
        console.log(err)
        loading.value = false
      }
    }

    const addToActiveChat = (msg, sessionId) => {
      const pos = searchPosition(sessionId, 'chat')
      if (pos >= 0) {
        const body = msg.type === 'document' ? `Nombre del archivo: Documento \n\n Documento: <$${msg.msgId}$>\n\n` : `Imagen: <$${msg.msgId}$>\n\n`
        allChats.value[pos].chatMsgs.push({
          message: body,
          time: new Date(),
          type: 'out',
          typeMsg: msg.type,
        })
        const { type } = getActiveOrPendingContact(sessionId)
        changeLastMessage(type, { sesionId: sessionId, info: body })
        if (activeChat.value.type === 'pending') {
          const positionPendingChat = searchPosition(
            activeChat.value.chat.id,
            'pending',
          )
          changeContactStatus(positionPendingChat)
        }
        nextTick(() => {
          scrollToBottomInChatLog()
        })
      }
    }

    const moveChatToDown = () => {
      nextTick(() => {
        scrollToBottomInChatLog()
      })
    }

    const toBase64 = data => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(data)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
    const sendMediaMsg = async () => {
      let resp = {}
      if (file.value) {
        const maxSize = 5000000
        if (file.value.size <= maxSize) {
          const base64 = await toBase64(file.value)
          if (base64) {
            const sessionId = activeChat.value.contact.id
            loading.value = true
            try {
              const response = await axios.post(`${URL}/sendmedia-whatsapp-message`, {
                sessionId,
                base64,
              })
              if (response.status === 200) {
                resp = {
                  success: true,
                  data: { ...response.data },
                }
                file.value = null
                addToActiveChat(response.data, sessionId)
              }
              loading.value = false
            } catch (error) {
              console.log(error)
              resp = {
                success: false,
                data: { ...error },
              }
              loading.value = false
            }
          }
        } else {
          resp = {
            success: false,
            data: { msg: 'The file exceeded the maximum size' },
          }
        }
      } else {
        resp = {
          success: false,
          data: { msg: 'File empty' },
        }
      }
      return resp
    }
    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,
      socket,
      // Chat & Contacts
      activeContacts,
      pendingContacts,
      file,
      loading,
      loadingModal,
      searchPosition,
      insertChat,
      getActiveOrPendingContact,
      addPendingContacts,
      changeLastMessage,
      // Single Chat
      refChatLogPS,
      activeChat,
      allChats,
      chatInputMessage,
      openChatOfContact,
      sendMessage,
      closeSession,
      getAllSessions,
      // Profile User Minimal Data
      profileUserDataMinimal,
      sendMediaMsg,
      addMsgToAllChats,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
      Accept,
      moveChatToDown,
    }
  },
  mounted() {
    const payload = {
      toast: this.$toast,
    }
    this.$store.dispatch('ticket/getSupportTypes', payload)
    this.$store.dispatch('ticket/getTicketTypes', payload)
    const agentInfo = localStorage.getItem('agentInfo')
      ? JSON.parse(localStorage.getItem('agentInfo'))
      : null

    const { email } = agentInfo
    this.connectToSocket(email)
  },
  methods: {
    connectToSocket(email, init = true) {
      this.socket = new WebSocket(
        `${urlWebSocket}?user=${email}&token=5370656c6c74795f74696d655f746f5f6368616e6765`,
      )

      this.socket.addEventListener('open', event => {
        console.log("open");
        if (init) {
          this.getAllSessions(email)
        }
      })

      this.socket.addEventListener('message', event => {
        const data = JSON.parse(event.data)
        if (data.action === 'newUser') {
          this.addPendingContacts(data)
        } else if (data.action === 'newMsg') {
          const pos = this.searchPosition(data.sesionId, 'chat')
          if (pos >= 0) {
            this.addMsgToAllChats(pos, data, 'in')
          } else {
            this.insertChat({
              ...data,
              info: [
                {
                  message: data.info,
                  time: new Date(),
                  type: 'in',
                  typeMsg: data.type,
                },
              ],
            })
          }
          const { type } = this.getActiveOrPendingContact(data.sesionId)
          this.changeLastMessage(type, data)
          if (this.activeChat?.contact?.id && data.sesionId === this.activeChat.contact.id) {
            this.moveChatToDown()
          }
        }
      })

      this.socket.addEventListener('error', event => {
        console.log(event)
      })

      this.socket.addEventListener('close', event => {
        const socketCloseCode = 1006
        const socketCloseSesion = 1000
        console.log("closeee", event);
        if (event.code !== socketCloseCode && event.code !== socketCloseSesion) {
          setTimeout(() => {
          this.connectToSocket(email, false)
        }, 1000)
        }

      })
    },
    confirmClose() {
      this.$swal({
        title: 'Esta seguro?',
        text: 'Desea finalizar este chat ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.closeSession()
          this.$swal({
            icon: 'success',
            title: 'Finalizado!',
            text: 'Se ha cerrado el chat',
          })
        }
      })
    },
    async senMediaMsgButton() {
      const response = await this.sendMediaMsg()
      if (response.success) {
        this.$swal({
          icon: 'success',
          title: 'Mensaje enviado!',
          text: 'Se ha enviado el archivo !',
        })
        this.$bvModal.hide('modal-2')
      } else if (!response.success && response.data?.msg === 'File empty') {
        this.$swal({
          icon: 'warning',
          title: 'Seleccione un archivo',
          text: 'Debe seleccionar un archivo para enviar el mensaje',
        })
      } else if (!response.success && response.data?.msg === 'The file exceeded the maximum size') {
        this.$swal({
          icon: 'warning',
          title: 'Excede el tamaño maximo',
          text: 'Este archivo excede el tamaño maximo de 5mb',
        })
      } else {
        this.$swal({
          icon: 'error',
          title: 'Ocurrio un error!',
          text: 'Ha ocurrido un error',
        })
      }
    },
  },
  computed: {
    ...mapState({
      timeToChangeStatus: state => state.timeToChangeStatus,
    }),
  },
}
</script>

<style scoped lang="scss">
.time-change{
  width: 370px;
  height: calc(100%);
  background-color: #fff;
  border-left: 1px solid #ebe9f1
}
.active-chat{
  padding-left: 1rem;
  width: calc(100% - 370px);
}
.start-chat-area{
  padding: 1rem;
  width: calc(100% - 370px);
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
