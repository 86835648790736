let urlWebSocket = 'wss://qo26aadzhk.execute-api.us-west-2.amazonaws.com/qa'
let urlApiServices = 'https://u5vmmyx370.execute-api.us-west-2.amazonaws.com/qa'

const mode = process.env.NODE_ENV

if (mode === 'production' && !window?.origin?.includes('brilla-qa.spellty')) {
  urlWebSocket = 'wss://pqujnbmz3g.execute-api.us-west-2.amazonaws.com/pdn'
  urlApiServices = 'https://5t4dcafec1.execute-api.us-west-2.amazonaws.com/pdn'
}

module.exports = {
  urlWebSocket,
  urlApiServices,
}
