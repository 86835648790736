export const cityCodes = [
  {
    'Código Departamento': '05',
    'value': '05001',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'MEDELLÍN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05002',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ABEJORRAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05004',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ABRIAQUÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05021',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ALEJANDRÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05030',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'AMAGÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05031',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'AMALFI',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05034',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ANDES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05036',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ANGELÓPOLIS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05038',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ANGOSTURA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05040',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ANORÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05042',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SANTA FÉ DE ANTIOQUIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05044',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ANZÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05045',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'APARTADÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05051',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ARBOLETES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05055',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ARGELIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05059',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ARMENIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05079',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'BARBOSA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05086',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'BELMIRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05088',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'BELLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05091',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'BETANIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05093',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'BETULIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05101',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'CIUDAD BOLÍVAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05107',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'BRICEÑO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05113',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'BURITICÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05120',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'CÁCERES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05125',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'CAICEDO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05129',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'CALDAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05134',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'CAMPAMENTO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05138',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'CAÑASGORDAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05142',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'CARACOLÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05145',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'CARAMANTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05147',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'CAREPA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05148',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'EL CARMEN DE VIBORAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05150',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'CAROLINA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05154',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'CAUCASIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05172',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'CHIGORODÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05190',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'CISNEROS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05197',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'COCORNÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05206',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'CONCEPCIÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05209',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'CONCORDIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05212',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'COPACABANA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05234',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'DABEIBA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05237',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'DONMATÍAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05240',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'EBÉJICO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05250',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'EL BAGRE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05264',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ENTRERRÍOS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05266',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ENVIGADO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05282',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'FREDONIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05284',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'FRONTINO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05306',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'GIRALDO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05308',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'GIRARDOTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05310',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'GÓMEZ PLATA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05313',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'GRANADA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05315',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'GUADALUPE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05318',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'GUARNE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05321',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'GUATAPÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05347',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'HELICONIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05353',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'HISPANIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05360',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ITAGÜÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05361',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ITUANGO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05364',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'JARDÍN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05368',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'JERICÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05376',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'LA CEJA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05380',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'LA ESTRELLA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05390',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'LA PINTADA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05400',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'LA UNIÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05411',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'LIBORINA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05425',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'MACEO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05440',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'MARINILLA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05467',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'MONTEBELLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05475',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'MURINDÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05480',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'MUTATÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05483',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'NARIÑO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05490',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'NECOCLÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05495',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'NECHÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05501',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'OLAYA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05541',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'PEÑOL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05543',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'PEQUE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05576',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'PUEBLORRICO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05579',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'PUERTO BERRÍO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05585',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'PUERTO NARE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05591',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'PUERTO TRIUNFO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05604',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'REMEDIOS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05607',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'RETIRO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05615',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'RIONEGRO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05628',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SABANALARGA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05631',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SABANETA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05642',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SALGAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05647',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SAN ANDRÉS DE CUERQUÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05649',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SAN CARLOS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05652',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SAN FRANCISCO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05656',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SAN JERÓNIMO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05658',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SAN JOSÉ DE LA MONTAÑA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05659',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SAN JUAN DE URABÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05660',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SAN LUIS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05664',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SAN PEDRO DE LOS MILAGROS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05665',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SAN PEDRO DE URABÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05667',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SAN RAFAEL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05670',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SAN ROQUE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05674',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SAN VICENTE FERRER',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05679',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SANTA BÁRBARA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05686',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SANTA ROSA DE OSOS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05690',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SANTO DOMINGO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05697',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'EL SANTUARIO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05736',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SEGOVIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05756',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SONSÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05761',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'SOPETRÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05789',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'TÁMESIS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05790',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'TARAZÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05792',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'TARSO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05809',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'TITIRIBÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05819',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'TOLEDO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05837',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'TURBO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05842',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'URAMITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05847',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'URRAO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05854',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'VALDIVIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05856',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'VALPARAÍSO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05858',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'VEGACHÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05861',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'VENECIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05873',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'VIGÍA DEL FUERTE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05885',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'YALÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05887',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'YARUMAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05890',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'YOLOMBÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05893',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'YONDÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '05',
    'value': '05895',
    'Nombre Departamento': 'ANTIOQUIA',
    'text': 'ZARAGOZA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08001',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'BARRANQUILLA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08078',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'BARANOA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08137',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'CAMPO DE LA CRUZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08141',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'CANDELARIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08296',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'GALAPA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08372',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'JUAN DE ACOSTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08421',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'LURUACO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08433',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'MALAMBO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08436',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'MANATÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08520',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'PALMAR DE VARELA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08549',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'PIOJÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08558',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'POLONUEVO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08560',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'PONEDERA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08573',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'PUERTO COLOMBIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08606',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'REPELÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08634',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'SABANAGRANDE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08638',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'SABANALARGA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08675',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'SANTA LUCÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08685',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'SANTO TOMÁS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08758',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'SOLEDAD',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08770',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'SUAN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08832',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'TUBARÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': '08',
    'value': '08849',
    'Nombre Departamento': 'ATLÁNTICO',
    'text': 'USIACURÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 11,
    'value': 11001,
    'Nombre Departamento': 'BOGOTÁ. D.C.',
    'text': 'BOGOTÁ. D.C.',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13001,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'CARTAGENA DE INDIAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13006,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'ACHÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13030,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'ALTOS DEL ROSARIO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13042,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'ARENAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13052,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'ARJONA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13062,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'ARROYOHONDO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13074,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'BARRANCO DE LOBA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13140,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'CALAMAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13160,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'CANTAGALLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13188,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'CICUCO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13212,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'CÓRDOBA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13222,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'CLEMENCIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13244,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'EL CARMEN DE BOLÍVAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13248,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'EL GUAMO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13268,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'EL PEÑÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13300,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'HATILLO DE LOBA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13430,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'MAGANGUÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13433,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'MAHATES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13440,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'MARGARITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13442,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'MARÍA LA BAJA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13458,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'MONTECRISTO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13468,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'SANTA CRUZ DE MOMPOX',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13473,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'MORALES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13490,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'NOROSÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13549,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'PINILLOS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13580,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'REGIDOR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13600,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'RÍO VIEJO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13620,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'SAN CRISTÓBAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13647,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'SAN ESTANISLAO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13650,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'SAN FERNANDO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13654,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'SAN JACINTO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13655,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'SAN JACINTO DEL CAUCA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13657,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'SAN JUAN NEPOMUCENO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13667,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'SAN MARTÍN DE LOBA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13670,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'SAN PABLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13673,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'SANTA CATALINA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13683,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'SANTA ROSA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13688,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'SANTA ROSA DEL SUR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13744,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'SIMITÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13760,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'SOPLAVIENTO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13780,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'TALAIGUA NUEVO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13810,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'TIQUISIO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13836,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'TURBACO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13838,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'TURBANÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13873,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'VILLANUEVA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 13,
    'value': 13894,
    'Nombre Departamento': 'BOLÍVAR',
    'text': 'ZAMBRANO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15001,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'TUNJA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15022,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'ALMEIDA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15047,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'AQUITANIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15051,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'ARCABUCO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15087,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'BELÉN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15090,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'BERBEO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15092,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'BETÉITIVA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15097,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'BOAVITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15104,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'BOYACÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15106,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'BRICEÑO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15109,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'BUENAVISTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15114,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'BUSBANZÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15131,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CALDAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15135,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CAMPOHERMOSO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15162,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CERINZA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15172,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CHINAVITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15176,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CHIQUINQUIRÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15180,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CHISCAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15183,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CHITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15185,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CHITARAQUE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15187,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CHIVATÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15189,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CIÉNEGA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15204,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CÓMBITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15212,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'COPER',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15215,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CORRALES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15218,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'COVARACHÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15223,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CUBARÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19318,
    'Nombre Departamento': 'CAUCA',
    'text': 'GUAPI',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15224,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CUCAITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15226,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CUÍTIVA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15232,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CHÍQUIZA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15236,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'CHIVOR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15238,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'DUITAMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15244,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'EL COCUY',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15248,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'EL ESPINO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15272,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'FIRAVITOBA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15276,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'FLORESTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15293,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'GACHANTIVÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15296,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'GÁMEZA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15299,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'GARAGOA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15317,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'GUACAMAYAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15322,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'GUATEQUE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15325,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'GUAYATÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15332,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'GÜICÁN DE LA SIERRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15362,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'IZA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15367,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'JENESANO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15368,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'JERICÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15377,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'LABRANZAGRANDE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15380,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'LA CAPILLA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15401,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'LA VICTORIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15403,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'LA UVITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15407,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'VILLA DE LEYVA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15425,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'MACANAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15442,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'MARIPÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15455,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'MIRAFLORES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15464,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'MONGUA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15466,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'MONGUÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15469,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'MONIQUIRÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15476,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'MOTAVITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15480,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'MUZO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15491,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'NOBSA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15494,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'NUEVO COLÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15500,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'OICATÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15507,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'OTANCHE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15511,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'PACHAVITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15514,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'PÁEZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15516,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'PAIPA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15518,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'PAJARITO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15522,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'PANQUEBA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15531,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'PAUNA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15533,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'PAYA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15537,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'PAZ DE RÍO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15542,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'PESCA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15550,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'PISBA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15572,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'PUERTO BOYACÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15580,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'QUÍPAMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15599,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'RAMIRIQUÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15600,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'RÁQUIRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15621,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'RONDÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15632,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SABOYÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15638,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SÁCHICA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15646,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SAMACÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15660,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SAN EDUARDO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15664,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SAN JOSÉ DE PARE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15667,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SAN LUIS DE GACENO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15673,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SAN MATEO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15676,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SAN MIGUEL DE SEMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15681,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SAN PABLO DE BORBUR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15686,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SANTANA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15690,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SANTA MARÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15693,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SANTA ROSA DE VITERBO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15696,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SANTA SOFÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15720,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SATIVANORTE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15723,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SATIVASUR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15740,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SIACHOQUE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15753,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SOATÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15755,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SOCOTÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15757,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SOCHA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15759,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SOGAMOSO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15761,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SOMONDOCO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15762,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SORA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15763,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SOTAQUIRÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15764,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SORACÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15774,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SUSACÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15776,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SUTAMARCHÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15778,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'SUTATENZA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15790,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'TASCO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15798,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'TENZA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15804,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'TIBANÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15806,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'TIBASOSA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15808,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'TINJACÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15810,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'TIPACOQUE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15814,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'TOCA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15816,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'TOGÜÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15820,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'TÓPAGA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15822,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'TOTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15832,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'TUNUNGUÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15835,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'TURMEQUÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15837,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'TUTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15839,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'TUTAZÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15842,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'ÚMBITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15861,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'VENTAQUEMADA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15879,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'VIRACACHÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 15,
    'value': 15897,
    'Nombre Departamento': 'BOYACÁ',
    'text': 'ZETAQUIRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17001,
    'Nombre Departamento': 'CALDAS',
    'text': 'MANIZALES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17013,
    'Nombre Departamento': 'CALDAS',
    'text': 'AGUADAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17042,
    'Nombre Departamento': 'CALDAS',
    'text': 'ANSERMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17050,
    'Nombre Departamento': 'CALDAS',
    'text': 'ARANZAZU',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17088,
    'Nombre Departamento': 'CALDAS',
    'text': 'BELALCÁZAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17174,
    'Nombre Departamento': 'CALDAS',
    'text': 'CHINCHINÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17272,
    'Nombre Departamento': 'CALDAS',
    'text': 'FILADELFIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17380,
    'Nombre Departamento': 'CALDAS',
    'text': 'LA DORADA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17388,
    'Nombre Departamento': 'CALDAS',
    'text': 'LA MERCED',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17433,
    'Nombre Departamento': 'CALDAS',
    'text': 'MANZANARES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17442,
    'Nombre Departamento': 'CALDAS',
    'text': 'MARMATO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17444,
    'Nombre Departamento': 'CALDAS',
    'text': 'MARQUETALIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17446,
    'Nombre Departamento': 'CALDAS',
    'text': 'MARULANDA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17486,
    'Nombre Departamento': 'CALDAS',
    'text': 'NEIRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17495,
    'Nombre Departamento': 'CALDAS',
    'text': 'NORCASIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17513,
    'Nombre Departamento': 'CALDAS',
    'text': 'PÁCORA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17524,
    'Nombre Departamento': 'CALDAS',
    'text': 'PALESTINA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17541,
    'Nombre Departamento': 'CALDAS',
    'text': 'PENSILVANIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17614,
    'Nombre Departamento': 'CALDAS',
    'text': 'RIOSUCIO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17616,
    'Nombre Departamento': 'CALDAS',
    'text': 'RISARALDA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17653,
    'Nombre Departamento': 'CALDAS',
    'text': 'SALAMINA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17662,
    'Nombre Departamento': 'CALDAS',
    'text': 'SAMANÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17665,
    'Nombre Departamento': 'CALDAS',
    'text': 'SAN JOSÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17777,
    'Nombre Departamento': 'CALDAS',
    'text': 'SUPÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17867,
    'Nombre Departamento': 'CALDAS',
    'text': 'VICTORIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17873,
    'Nombre Departamento': 'CALDAS',
    'text': 'VILLAMARÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 17,
    'value': 17877,
    'Nombre Departamento': 'CALDAS',
    'text': 'VITERBO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18001,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'FLORENCIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18029,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'ALBANIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18094,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'BELÉN DE LOS ANDAQUÍES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18150,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'CARTAGENA DEL CHAIRÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18205,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'CURILLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18247,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'EL DONCELLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18256,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'EL PAUJÍL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18410,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'LA MONTAÑITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18460,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'MILÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18479,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'MORELIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18592,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'PUERTO RICO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18610,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'SAN JOSÉ DEL FRAGUA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18753,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'SAN VICENTE DEL CAGUÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18756,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'SOLANO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18785,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'SOLITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 18,
    'value': 18860,
    'Nombre Departamento': 'CAQUETÁ',
    'text': 'VALPARAÍSO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19001,
    'Nombre Departamento': 'CAUCA',
    'text': 'POPAYÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19022,
    'Nombre Departamento': 'CAUCA',
    'text': 'ALMAGUER',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19050,
    'Nombre Departamento': 'CAUCA',
    'text': 'ARGELIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19075,
    'Nombre Departamento': 'CAUCA',
    'text': 'BALBOA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19100,
    'Nombre Departamento': 'CAUCA',
    'text': 'BOLÍVAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19110,
    'Nombre Departamento': 'CAUCA',
    'text': 'BUENOS AIRES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19130,
    'Nombre Departamento': 'CAUCA',
    'text': 'CAJIBÍO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19137,
    'Nombre Departamento': 'CAUCA',
    'text': 'CALDONO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19142,
    'Nombre Departamento': 'CAUCA',
    'text': 'CALOTO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19212,
    'Nombre Departamento': 'CAUCA',
    'text': 'CORINTO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19256,
    'Nombre Departamento': 'CAUCA',
    'text': 'EL TAMBO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19290,
    'Nombre Departamento': 'CAUCA',
    'text': 'FLORENCIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19300,
    'Nombre Departamento': 'CAUCA',
    'text': 'GUACHENÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19355,
    'Nombre Departamento': 'CAUCA',
    'text': 'INZÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19364,
    'Nombre Departamento': 'CAUCA',
    'text': 'JAMBALÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19392,
    'Nombre Departamento': 'CAUCA',
    'text': 'LA SIERRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19397,
    'Nombre Departamento': 'CAUCA',
    'text': 'LA VEGA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19418,
    'Nombre Departamento': 'CAUCA',
    'text': 'LÓPEZ DE MICAY',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19450,
    'Nombre Departamento': 'CAUCA',
    'text': 'MERCADERES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19455,
    'Nombre Departamento': 'CAUCA',
    'text': 'MIRANDA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19473,
    'Nombre Departamento': 'CAUCA',
    'text': 'MORALES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19513,
    'Nombre Departamento': 'CAUCA',
    'text': 'PADILLA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19517,
    'Nombre Departamento': 'CAUCA',
    'text': 'PÁEZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19532,
    'Nombre Departamento': 'CAUCA',
    'text': 'PATÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19533,
    'Nombre Departamento': 'CAUCA',
    'text': 'PIAMONTE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19548,
    'Nombre Departamento': 'CAUCA',
    'text': 'PIENDAMÓ - TUNÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19573,
    'Nombre Departamento': 'CAUCA',
    'text': 'PUERTO TEJADA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19585,
    'Nombre Departamento': 'CAUCA',
    'text': 'PURACÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19622,
    'Nombre Departamento': 'CAUCA',
    'text': 'ROSAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19693,
    'Nombre Departamento': 'CAUCA',
    'text': 'SAN SEBASTIÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19698,
    'Nombre Departamento': 'CAUCA',
    'text': 'SANTANDER DE QUILICHAO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19701,
    'Nombre Departamento': 'CAUCA',
    'text': 'SANTA ROSA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19743,
    'Nombre Departamento': 'CAUCA',
    'text': 'SILVIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19760,
    'Nombre Departamento': 'CAUCA',
    'text': 'SOTARÁ PAISPAMBA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19780,
    'Nombre Departamento': 'CAUCA',
    'text': 'SUÁREZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19785,
    'Nombre Departamento': 'CAUCA',
    'text': 'SUCRE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19807,
    'Nombre Departamento': 'CAUCA',
    'text': 'TIMBÍO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19809,
    'Nombre Departamento': 'CAUCA',
    'text': 'TIMBIQUÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19821,
    'Nombre Departamento': 'CAUCA',
    'text': 'TORIBÍO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19824,
    'Nombre Departamento': 'CAUCA',
    'text': 'TOTORÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 19,
    'value': 19845,
    'Nombre Departamento': 'CAUCA',
    'text': 'VILLA RICA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20001,
    'Nombre Departamento': 'CESAR',
    'text': 'VALLEDUPAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20011,
    'Nombre Departamento': 'CESAR',
    'text': 'AGUACHICA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20013,
    'Nombre Departamento': 'CESAR',
    'text': 'AGUSTÍN CODAZZI',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20032,
    'Nombre Departamento': 'CESAR',
    'text': 'ASTREA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20045,
    'Nombre Departamento': 'CESAR',
    'text': 'BECERRIL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20060,
    'Nombre Departamento': 'CESAR',
    'text': 'BOSCONIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20175,
    'Nombre Departamento': 'CESAR',
    'text': 'CHIMICHAGUA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20178,
    'Nombre Departamento': 'CESAR',
    'text': 'CHIRIGUANÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20228,
    'Nombre Departamento': 'CESAR',
    'text': 'CURUMANÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20238,
    'Nombre Departamento': 'CESAR',
    'text': 'EL COPEY',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20250,
    'Nombre Departamento': 'CESAR',
    'text': 'EL PASO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20295,
    'Nombre Departamento': 'CESAR',
    'text': 'GAMARRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20310,
    'Nombre Departamento': 'CESAR',
    'text': 'GONZÁLEZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20383,
    'Nombre Departamento': 'CESAR',
    'text': 'LA GLORIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20400,
    'Nombre Departamento': 'CESAR',
    'text': 'LA JAGUA DE IBIRICO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20443,
    'Nombre Departamento': 'CESAR',
    'text': 'MANAURE BALCÓN DEL CESAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20517,
    'Nombre Departamento': 'CESAR',
    'text': 'PAILITAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20550,
    'Nombre Departamento': 'CESAR',
    'text': 'PELAYA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20570,
    'Nombre Departamento': 'CESAR',
    'text': 'PUEBLO BELLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20614,
    'Nombre Departamento': 'CESAR',
    'text': 'RÍO DE ORO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20621,
    'Nombre Departamento': 'CESAR',
    'text': 'LA PAZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20710,
    'Nombre Departamento': 'CESAR',
    'text': 'SAN ALBERTO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20750,
    'Nombre Departamento': 'CESAR',
    'text': 'SAN DIEGO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20770,
    'Nombre Departamento': 'CESAR',
    'text': 'SAN MARTÍN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 20,
    'value': 20787,
    'Nombre Departamento': 'CESAR',
    'text': 'TAMALAMEQUE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23001,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'MONTERÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23068,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'AYAPEL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23079,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'BUENAVISTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23090,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'CANALETE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23162,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'CERETÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23168,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'CHIMÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23182,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'CHINÚ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23189,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'CIÉNAGA DE ORO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23300,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'COTORRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23350,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'LA APARTADA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23417,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'LORICA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23419,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'LOS CÓRDOBAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23464,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'MOMIL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23466,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'MONTELÍBANO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23500,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'MOÑITOS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23555,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'PLANETA RICA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23570,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'PUEBLO NUEVO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23574,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'PUERTO ESCONDIDO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23580,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'PUERTO LIBERTADOR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23586,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'PURÍSIMA DE LA CONCEPCIÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23660,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'SAHAGÚN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23670,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'SAN ANDRÉS DE SOTAVENTO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23672,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'SAN ANTERO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23675,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'SAN BERNARDO DEL VIENTO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23678,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'SAN CARLOS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23682,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'SAN JOSÉ DE URÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23686,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'SAN PELAYO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23807,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'TIERRALTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23815,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'TUCHÍN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 23,
    'value': 23855,
    'Nombre Departamento': 'CÓRDOBA',
    'text': 'VALENCIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25001,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'AGUA DE DIOS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25019,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'ALBÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25035,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'ANAPOIMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25040,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'ANOLAIMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25053,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'ARBELÁEZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25086,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'BELTRÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25095,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'BITUIMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25099,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'BOJACÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25120,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'CABRERA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25123,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'CACHIPAY',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25126,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'CAJICÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25148,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'CAPARRAPÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25151,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'CÁQUEZA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25154,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'CARMEN DE CARUPA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25168,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'CHAGUANÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25175,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'CHÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25178,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'CHIPAQUE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25181,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'CHOACHÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25183,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'CHOCONTÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25200,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'COGUA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25214,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'COTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25224,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'CUCUNUBÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25245,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'EL COLEGIO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25258,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'EL PEÑÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25260,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'EL ROSAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25269,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'FACATATIVÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25279,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'FÓMEQUE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25281,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'FOSCA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25286,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'FUNZA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25288,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'FÚQUENE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25290,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'FUSAGASUGÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25293,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'GACHALÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25295,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'GACHANCIPÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25297,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'GACHETÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25299,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'GAMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25307,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'GIRARDOT',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25312,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'GRANADA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25317,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'GUACHETÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25320,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'GUADUAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25322,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'GUASCA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25324,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'GUATAQUÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25326,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'GUATAVITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25328,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'GUAYABAL DE SÍQUIMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25335,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'GUAYABETAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25339,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'GUTIÉRREZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25368,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'JERUSALÉN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25372,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'JUNÍN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25377,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'LA CALERA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25386,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'LA MESA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25394,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'LA PALMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25398,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'LA PEÑA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25402,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'LA VEGA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25407,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'LENGUAZAQUE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25426,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'MACHETÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25430,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'MADRID',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25436,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'MANTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25438,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'MEDINA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25473,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'MOSQUERA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25483,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'NARIÑO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25486,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'NEMOCÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25488,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'NILO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25489,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'NIMAIMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25491,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'NOCAIMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25506,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'VENECIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25513,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'PACHO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25518,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'PAIME',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25524,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'PANDI',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25530,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'PARATEBUENO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25535,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'PASCA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25572,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'PUERTO SALGAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25580,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'PULÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25592,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'QUEBRADANEGRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25594,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'QUETAME',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25596,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'QUIPILE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25599,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'APULO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25612,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'RICAURTE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25645,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SAN ANTONIO DEL TEQUENDAMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25649,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SAN BERNARDO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25653,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SAN CAYETANO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25658,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SAN FRANCISCO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25662,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SAN JUAN DE RIOSECO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25718,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SASAIMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25736,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SESQUILÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25740,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SIBATÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25743,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SILVANIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25745,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SIMIJACA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25754,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SOACHA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25758,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SOPÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25769,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SUBACHOQUE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25772,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SUESCA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25777,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SUPATÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25779,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SUSA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25781,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'SUTATAUSA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25785,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'TABIO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25793,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'TAUSA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25797,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'TENA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25799,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'TENJO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25805,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'TIBACUY',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25807,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'TIBIRITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25815,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'TOCAIMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25817,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'TOCANCIPÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25823,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'TOPAIPÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25839,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'UBALÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25841,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'UBAQUE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25843,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'VILLA DE SAN DIEGO DE UBATÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25845,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'UNE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25851,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'ÚTICA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25862,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'VERGARA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25867,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'VIANÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25871,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'VILLAGÓMEZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25873,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'VILLAPINZÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25875,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'VILLETA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25878,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'VIOTÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25885,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'YACOPÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25898,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'ZIPACÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 25,
    'value': 25899,
    'Nombre Departamento': 'CUNDINAMARCA',
    'text': 'ZIPAQUIRÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27001,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'QUIBDÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27006,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'ACANDÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27025,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'ALTO BAUDÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27050,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'ATRATO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27073,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'BAGADÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27075,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'BAHÍA SOLANO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27077,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'BAJO BAUDÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27099,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'BOJAYÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27135,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'EL CANTÓN DEL SAN PABLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27150,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'CARMEN DEL DARIÉN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27160,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'CÉRTEGUI',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27205,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'CONDOTO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27245,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'EL CARMEN DE ATRATO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27250,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'EL LITORAL DEL SAN JUAN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27361,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'ISTMINA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27372,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'JURADÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27413,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'LLORÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27425,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'MEDIO ATRATO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27430,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'MEDIO BAUDÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27450,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'MEDIO SAN JUAN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27491,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'NÓVITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27495,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'NUQUÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27580,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'RÍO IRÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27600,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'RÍO QUITO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27615,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'RIOSUCIO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27660,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'SAN JOSÉ DEL PALMAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27745,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'SIPÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27787,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'TADÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27800,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'UNGUÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 27,
    'value': 27810,
    'Nombre Departamento': 'CHOCÓ',
    'text': 'UNIÓN PANAMERICANA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41001,
    'Nombre Departamento': 'HUILA',
    'text': 'NEIVA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41006,
    'Nombre Departamento': 'HUILA',
    'text': 'ACEVEDO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41013,
    'Nombre Departamento': 'HUILA',
    'text': 'AGRADO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41016,
    'Nombre Departamento': 'HUILA',
    'text': 'AIPE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41020,
    'Nombre Departamento': 'HUILA',
    'text': 'ALGECIRAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41026,
    'Nombre Departamento': 'HUILA',
    'text': 'ALTAMIRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41078,
    'Nombre Departamento': 'HUILA',
    'text': 'BARAYA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41132,
    'Nombre Departamento': 'HUILA',
    'text': 'CAMPOALEGRE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41206,
    'Nombre Departamento': 'HUILA',
    'text': 'COLOMBIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41244,
    'Nombre Departamento': 'HUILA',
    'text': 'ELÍAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41298,
    'Nombre Departamento': 'HUILA',
    'text': 'GARZÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41306,
    'Nombre Departamento': 'HUILA',
    'text': 'GIGANTE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41319,
    'Nombre Departamento': 'HUILA',
    'text': 'GUADALUPE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41349,
    'Nombre Departamento': 'HUILA',
    'text': 'HOBO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41357,
    'Nombre Departamento': 'HUILA',
    'text': 'ÍQUIRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41359,
    'Nombre Departamento': 'HUILA',
    'text': 'ISNOS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41378,
    'Nombre Departamento': 'HUILA',
    'text': 'LA ARGENTINA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41396,
    'Nombre Departamento': 'HUILA',
    'text': 'LA PLATA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41483,
    'Nombre Departamento': 'HUILA',
    'text': 'NÁTAGA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41503,
    'Nombre Departamento': 'HUILA',
    'text': 'OPORAPA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41518,
    'Nombre Departamento': 'HUILA',
    'text': 'PAICOL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41524,
    'Nombre Departamento': 'HUILA',
    'text': 'PALERMO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41530,
    'Nombre Departamento': 'HUILA',
    'text': 'PALESTINA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41548,
    'Nombre Departamento': 'HUILA',
    'text': 'PITAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41551,
    'Nombre Departamento': 'HUILA',
    'text': 'PITALITO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41615,
    'Nombre Departamento': 'HUILA',
    'text': 'RIVERA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41660,
    'Nombre Departamento': 'HUILA',
    'text': 'SALADOBLANCO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41668,
    'Nombre Departamento': 'HUILA',
    'text': 'SAN AGUSTÍN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41676,
    'Nombre Departamento': 'HUILA',
    'text': 'SANTA MARÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41770,
    'Nombre Departamento': 'HUILA',
    'text': 'SUAZA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41791,
    'Nombre Departamento': 'HUILA',
    'text': 'TARQUI',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41797,
    'Nombre Departamento': 'HUILA',
    'text': 'TESALIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41799,
    'Nombre Departamento': 'HUILA',
    'text': 'TELLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41801,
    'Nombre Departamento': 'HUILA',
    'text': 'TERUEL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41807,
    'Nombre Departamento': 'HUILA',
    'text': 'TIMANÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41872,
    'Nombre Departamento': 'HUILA',
    'text': 'VILLAVIEJA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 41,
    'value': 41885,
    'Nombre Departamento': 'HUILA',
    'text': 'YAGUARÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 44,
    'value': 44001,
    'Nombre Departamento': 'LA GUAJIRA',
    'text': 'RIOHACHA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 44,
    'value': 44035,
    'Nombre Departamento': 'LA GUAJIRA',
    'text': 'ALBANIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 44,
    'value': 44078,
    'Nombre Departamento': 'LA GUAJIRA',
    'text': 'BARRANCAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 44,
    'value': 44090,
    'Nombre Departamento': 'LA GUAJIRA',
    'text': 'DIBULLA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 44,
    'value': 44098,
    'Nombre Departamento': 'LA GUAJIRA',
    'text': 'DISTRACCIÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 44,
    'value': 44110,
    'Nombre Departamento': 'LA GUAJIRA',
    'text': 'EL MOLINO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 44,
    'value': 44279,
    'Nombre Departamento': 'LA GUAJIRA',
    'text': 'FONSECA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 44,
    'value': 44378,
    'Nombre Departamento': 'LA GUAJIRA',
    'text': 'HATONUEVO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 44,
    'value': 44420,
    'Nombre Departamento': 'LA GUAJIRA',
    'text': 'LA JAGUA DEL PILAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 44,
    'value': 44430,
    'Nombre Departamento': 'LA GUAJIRA',
    'text': 'MAICAO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 44,
    'value': 44560,
    'Nombre Departamento': 'LA GUAJIRA',
    'text': 'MANAURE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 44,
    'value': 44650,
    'Nombre Departamento': 'LA GUAJIRA',
    'text': 'SAN JUAN DEL CESAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 44,
    'value': 44847,
    'Nombre Departamento': 'LA GUAJIRA',
    'text': 'URIBIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 44,
    'value': 44855,
    'Nombre Departamento': 'LA GUAJIRA',
    'text': 'URUMITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 44,
    'value': 44874,
    'Nombre Departamento': 'LA GUAJIRA',
    'text': 'VILLANUEVA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47001,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'SANTA MARTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47030,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'ALGARROBO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47053,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'ARACATACA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47058,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'ARIGUANÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47161,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'CERRO DE SAN ANTONIO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47170,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'CHIVOLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47189,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'CIÉNAGA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47205,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'CONCORDIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47245,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'EL BANCO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47258,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'EL PIÑÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47268,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'EL RETÉN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47288,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'FUNDACIÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47318,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'GUAMAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47460,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'NUEVA GRANADA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47541,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'PEDRAZA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47545,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'PIJIÑO DEL CARMEN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47551,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'PIVIJAY',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47555,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'PLATO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47570,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'PUEBLOVIEJO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47605,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'REMOLINO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47660,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'SABANAS DE SAN ÁNGEL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47675,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'SALAMINA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47692,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'SAN SEBASTIÁN DE BUENAVISTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47703,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'SAN ZENÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47707,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'SANTA ANA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47720,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'SANTA BÁRBARA DE PINTO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47745,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'SITIONUEVO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47798,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'TENERIFE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47960,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'ZAPAYÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 47,
    'value': 47980,
    'Nombre Departamento': 'MAGDALENA',
    'text': 'ZONA BANANERA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50001,
    'Nombre Departamento': 'META',
    'text': 'VILLAVICENCIO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50006,
    'Nombre Departamento': 'META',
    'text': 'ACACÍAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50110,
    'Nombre Departamento': 'META',
    'text': 'BARRANCA DE UPÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50124,
    'Nombre Departamento': 'META',
    'text': 'CABUYARO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50150,
    'Nombre Departamento': 'META',
    'text': 'CASTILLA LA NUEVA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50223,
    'Nombre Departamento': 'META',
    'text': 'CUBARRAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50226,
    'Nombre Departamento': 'META',
    'text': 'CUMARAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50245,
    'Nombre Departamento': 'META',
    'text': 'EL CALVARIO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50251,
    'Nombre Departamento': 'META',
    'text': 'EL CASTILLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50270,
    'Nombre Departamento': 'META',
    'text': 'EL DORADO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50287,
    'Nombre Departamento': 'META',
    'text': 'FUENTE DE ORO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50313,
    'Nombre Departamento': 'META',
    'text': 'GRANADA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50318,
    'Nombre Departamento': 'META',
    'text': 'GUAMAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50325,
    'Nombre Departamento': 'META',
    'text': 'MAPIRIPÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50330,
    'Nombre Departamento': 'META',
    'text': 'MESETAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50350,
    'Nombre Departamento': 'META',
    'text': 'LA MACARENA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50370,
    'Nombre Departamento': 'META',
    'text': 'URIBE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50400,
    'Nombre Departamento': 'META',
    'text': 'LEJANÍAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50450,
    'Nombre Departamento': 'META',
    'text': 'PUERTO CONCORDIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50568,
    'Nombre Departamento': 'META',
    'text': 'PUERTO GAITÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50573,
    'Nombre Departamento': 'META',
    'text': 'PUERTO LÓPEZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50577,
    'Nombre Departamento': 'META',
    'text': 'PUERTO LLERAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50590,
    'Nombre Departamento': 'META',
    'text': 'PUERTO RICO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50606,
    'Nombre Departamento': 'META',
    'text': 'RESTREPO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50680,
    'Nombre Departamento': 'META',
    'text': 'SAN CARLOS DE GUAROA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50683,
    'Nombre Departamento': 'META',
    'text': 'SAN JUAN DE ARAMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50686,
    'Nombre Departamento': 'META',
    'text': 'SAN JUANITO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50689,
    'Nombre Departamento': 'META',
    'text': 'SAN MARTÍN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 50,
    'value': 50711,
    'Nombre Departamento': 'META',
    'text': 'VISTAHERMOSA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52001,
    'Nombre Departamento': 'NARIÑO',
    'text': 'PASTO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52019,
    'Nombre Departamento': 'NARIÑO',
    'text': 'ALBÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52022,
    'Nombre Departamento': 'NARIÑO',
    'text': 'ALDANA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52036,
    'Nombre Departamento': 'NARIÑO',
    'text': 'ANCUYA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52051,
    'Nombre Departamento': 'NARIÑO',
    'text': 'ARBOLEDA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52079,
    'Nombre Departamento': 'NARIÑO',
    'text': 'BARBACOAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52083,
    'Nombre Departamento': 'NARIÑO',
    'text': 'BELÉN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52110,
    'Nombre Departamento': 'NARIÑO',
    'text': 'BUESACO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52203,
    'Nombre Departamento': 'NARIÑO',
    'text': 'COLÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52207,
    'Nombre Departamento': 'NARIÑO',
    'text': 'CONSACÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52210,
    'Nombre Departamento': 'NARIÑO',
    'text': 'CONTADERO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52215,
    'Nombre Departamento': 'NARIÑO',
    'text': 'CÓRDOBA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52224,
    'Nombre Departamento': 'NARIÑO',
    'text': 'CUASPUD CARLOSAMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52227,
    'Nombre Departamento': 'NARIÑO',
    'text': 'CUMBAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52233,
    'Nombre Departamento': 'NARIÑO',
    'text': 'CUMBITARA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52240,
    'Nombre Departamento': 'NARIÑO',
    'text': 'CHACHAGÜÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52250,
    'Nombre Departamento': 'NARIÑO',
    'text': 'EL CHARCO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52254,
    'Nombre Departamento': 'NARIÑO',
    'text': 'EL PEÑOL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52256,
    'Nombre Departamento': 'NARIÑO',
    'text': 'EL ROSARIO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52258,
    'Nombre Departamento': 'NARIÑO',
    'text': 'EL TABLÓN DE GÓMEZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52260,
    'Nombre Departamento': 'NARIÑO',
    'text': 'EL TAMBO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52287,
    'Nombre Departamento': 'NARIÑO',
    'text': 'FUNES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52317,
    'Nombre Departamento': 'NARIÑO',
    'text': 'GUACHUCAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52320,
    'Nombre Departamento': 'NARIÑO',
    'text': 'GUAITARILLA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52323,
    'Nombre Departamento': 'NARIÑO',
    'text': 'GUALMATÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52352,
    'Nombre Departamento': 'NARIÑO',
    'text': 'ILES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52354,
    'Nombre Departamento': 'NARIÑO',
    'text': 'IMUÉS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52356,
    'Nombre Departamento': 'NARIÑO',
    'text': 'IPIALES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52378,
    'Nombre Departamento': 'NARIÑO',
    'text': 'LA CRUZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52381,
    'Nombre Departamento': 'NARIÑO',
    'text': 'LA FLORIDA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52385,
    'Nombre Departamento': 'NARIÑO',
    'text': 'LA LLANADA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52390,
    'Nombre Departamento': 'NARIÑO',
    'text': 'LA TOLA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52399,
    'Nombre Departamento': 'NARIÑO',
    'text': 'LA UNIÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52405,
    'Nombre Departamento': 'NARIÑO',
    'text': 'LEIVA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52411,
    'Nombre Departamento': 'NARIÑO',
    'text': 'LINARES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52418,
    'Nombre Departamento': 'NARIÑO',
    'text': 'LOS ANDES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52427,
    'Nombre Departamento': 'NARIÑO',
    'text': 'MAGÜÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52435,
    'Nombre Departamento': 'NARIÑO',
    'text': 'MALLAMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52473,
    'Nombre Departamento': 'NARIÑO',
    'text': 'MOSQUERA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52480,
    'Nombre Departamento': 'NARIÑO',
    'text': 'NARIÑO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52490,
    'Nombre Departamento': 'NARIÑO',
    'text': 'OLAYA HERRERA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52506,
    'Nombre Departamento': 'NARIÑO',
    'text': 'OSPINA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52520,
    'Nombre Departamento': 'NARIÑO',
    'text': 'FRANCISCO PIZARRO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52540,
    'Nombre Departamento': 'NARIÑO',
    'text': 'POLICARPA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52560,
    'Nombre Departamento': 'NARIÑO',
    'text': 'POTOSÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52565,
    'Nombre Departamento': 'NARIÑO',
    'text': 'PROVIDENCIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52573,
    'Nombre Departamento': 'NARIÑO',
    'text': 'PUERRES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52585,
    'Nombre Departamento': 'NARIÑO',
    'text': 'PUPIALES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52612,
    'Nombre Departamento': 'NARIÑO',
    'text': 'RICAURTE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52621,
    'Nombre Departamento': 'NARIÑO',
    'text': 'ROBERTO PAYÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52678,
    'Nombre Departamento': 'NARIÑO',
    'text': 'SAMANIEGO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52683,
    'Nombre Departamento': 'NARIÑO',
    'text': 'SANDONÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52685,
    'Nombre Departamento': 'NARIÑO',
    'text': 'SAN BERNARDO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52687,
    'Nombre Departamento': 'NARIÑO',
    'text': 'SAN LORENZO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52693,
    'Nombre Departamento': 'NARIÑO',
    'text': 'SAN PABLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52694,
    'Nombre Departamento': 'NARIÑO',
    'text': 'SAN PEDRO DE CARTAGO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52696,
    'Nombre Departamento': 'NARIÑO',
    'text': 'SANTA BÁRBARA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52699,
    'Nombre Departamento': 'NARIÑO',
    'text': 'SANTACRUZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52720,
    'Nombre Departamento': 'NARIÑO',
    'text': 'SAPUYES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52786,
    'Nombre Departamento': 'NARIÑO',
    'text': 'TAMINANGO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52788,
    'Nombre Departamento': 'NARIÑO',
    'text': 'TANGUA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52835,
    'Nombre Departamento': 'NARIÑO',
    'text': 'SAN ANDRÉS DE TUMACO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52838,
    'Nombre Departamento': 'NARIÑO',
    'text': 'TÚQUERRES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 52,
    'value': 52885,
    'Nombre Departamento': 'NARIÑO',
    'text': 'YACUANQUER',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54001,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'SAN JOSÉ DE CÚCUTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54003,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'ÁBREGO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54051,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'ARBOLEDAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54099,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'BOCHALEMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54109,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'BUCARASICA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54125,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'CÁCOTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54128,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'CÁCHIRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54172,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'CHINÁCOTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54174,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'CHITAGÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54206,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'CONVENCIÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54223,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'CUCUTILLA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54239,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'DURANIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54245,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'EL CARMEN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54250,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'EL TARRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54261,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'EL ZULIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54313,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'GRAMALOTE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54344,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'HACARÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54347,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'HERRÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54377,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'LABATECA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54385,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'LA ESPERANZA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54398,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'LA PLAYA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54405,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'LOS PATIOS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54418,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'LOURDES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54480,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'MUTISCUA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54498,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'OCAÑA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54518,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'PAMPLONA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54520,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'PAMPLONITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54553,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'PUERTO SANTANDER',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54599,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'RAGONVALIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54660,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'SALAZAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54670,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'SAN CALIXTO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54673,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'SAN CAYETANO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54680,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'SANTIAGO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54720,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'SARDINATA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54743,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'SILOS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54800,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'TEORAMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54810,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'TIBÚ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54820,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'TOLEDO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54871,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'VILLA CARO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 54,
    'value': 54874,
    'Nombre Departamento': 'NORTE DE SANTANDER',
    'text': 'VILLA DEL ROSARIO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 63,
    'value': 63001,
    'Nombre Departamento': 'QUINDÍO',
    'text': 'ARMENIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 63,
    'value': 63111,
    'Nombre Departamento': 'QUINDÍO',
    'text': 'BUENAVISTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 63,
    'value': 63130,
    'Nombre Departamento': 'QUINDÍO',
    'text': 'CALARCÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 63,
    'value': 63190,
    'Nombre Departamento': 'QUINDÍO',
    'text': 'CIRCASIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 63,
    'value': 63212,
    'Nombre Departamento': 'QUINDÍO',
    'text': 'CÓRDOBA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 63,
    'value': 63272,
    'Nombre Departamento': 'QUINDÍO',
    'text': 'FILANDIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 63,
    'value': 63302,
    'Nombre Departamento': 'QUINDÍO',
    'text': 'GÉNOVA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 63,
    'value': 63401,
    'Nombre Departamento': 'QUINDÍO',
    'text': 'LA TEBAIDA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 63,
    'value': 63470,
    'Nombre Departamento': 'QUINDÍO',
    'text': 'MONTENEGRO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 63,
    'value': 63548,
    'Nombre Departamento': 'QUINDÍO',
    'text': 'PIJAO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 63,
    'value': 63594,
    'Nombre Departamento': 'QUINDÍO',
    'text': 'QUIMBAYA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 63,
    'value': 63690,
    'Nombre Departamento': 'QUINDÍO',
    'text': 'SALENTO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 66,
    'value': 66001,
    'Nombre Departamento': 'RISARALDA',
    'text': 'PEREIRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 66,
    'value': 66045,
    'Nombre Departamento': 'RISARALDA',
    'text': 'APÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 66,
    'value': 66075,
    'Nombre Departamento': 'RISARALDA',
    'text': 'BALBOA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 66,
    'value': 66088,
    'Nombre Departamento': 'RISARALDA',
    'text': 'BELÉN DE UMBRÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 66,
    'value': 66170,
    'Nombre Departamento': 'RISARALDA',
    'text': 'DOSQUEBRADAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 66,
    'value': 66318,
    'Nombre Departamento': 'RISARALDA',
    'text': 'GUÁTICA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 66,
    'value': 66383,
    'Nombre Departamento': 'RISARALDA',
    'text': 'LA CELIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 66,
    'value': 66400,
    'Nombre Departamento': 'RISARALDA',
    'text': 'LA VIRGINIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 66,
    'value': 66440,
    'Nombre Departamento': 'RISARALDA',
    'text': 'MARSELLA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 66,
    'value': 66456,
    'Nombre Departamento': 'RISARALDA',
    'text': 'MISTRATÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 66,
    'value': 66572,
    'Nombre Departamento': 'RISARALDA',
    'text': 'PUEBLO RICO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 66,
    'value': 66594,
    'Nombre Departamento': 'RISARALDA',
    'text': 'QUINCHÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 66,
    'value': 66682,
    'Nombre Departamento': 'RISARALDA',
    'text': 'SANTA ROSA DE CABAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 66,
    'value': 66687,
    'Nombre Departamento': 'RISARALDA',
    'text': 'SANTUARIO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68001,
    'Nombre Departamento': 'SANTANDER',
    'text': 'BUCARAMANGA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68013,
    'Nombre Departamento': 'SANTANDER',
    'text': 'AGUADA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68020,
    'Nombre Departamento': 'SANTANDER',
    'text': 'ALBANIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68051,
    'Nombre Departamento': 'SANTANDER',
    'text': 'ARATOCA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68077,
    'Nombre Departamento': 'SANTANDER',
    'text': 'BARBOSA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68079,
    'Nombre Departamento': 'SANTANDER',
    'text': 'BARICHARA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68081,
    'Nombre Departamento': 'SANTANDER',
    'text': 'BARRANCABERMEJA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68092,
    'Nombre Departamento': 'SANTANDER',
    'text': 'BETULIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68101,
    'Nombre Departamento': 'SANTANDER',
    'text': 'BOLÍVAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68121,
    'Nombre Departamento': 'SANTANDER',
    'text': 'CABRERA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68132,
    'Nombre Departamento': 'SANTANDER',
    'text': 'CALIFORNIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68147,
    'Nombre Departamento': 'SANTANDER',
    'text': 'CAPITANEJO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68152,
    'Nombre Departamento': 'SANTANDER',
    'text': 'CARCASÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68160,
    'Nombre Departamento': 'SANTANDER',
    'text': 'CEPITÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68162,
    'Nombre Departamento': 'SANTANDER',
    'text': 'CERRITO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68167,
    'Nombre Departamento': 'SANTANDER',
    'text': 'CHARALÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68169,
    'Nombre Departamento': 'SANTANDER',
    'text': 'CHARTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68176,
    'Nombre Departamento': 'SANTANDER',
    'text': 'CHIMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68179,
    'Nombre Departamento': 'SANTANDER',
    'text': 'CHIPATÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68190,
    'Nombre Departamento': 'SANTANDER',
    'text': 'CIMITARRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68207,
    'Nombre Departamento': 'SANTANDER',
    'text': 'CONCEPCIÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68209,
    'Nombre Departamento': 'SANTANDER',
    'text': 'CONFINES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68211,
    'Nombre Departamento': 'SANTANDER',
    'text': 'CONTRATACIÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68217,
    'Nombre Departamento': 'SANTANDER',
    'text': 'COROMORO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68229,
    'Nombre Departamento': 'SANTANDER',
    'text': 'CURITÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68235,
    'Nombre Departamento': 'SANTANDER',
    'text': 'EL CARMEN DE CHUCURI',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68245,
    'Nombre Departamento': 'SANTANDER',
    'text': 'EL GUACAMAYO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68250,
    'Nombre Departamento': 'SANTANDER',
    'text': 'EL PEÑÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68255,
    'Nombre Departamento': 'SANTANDER',
    'text': 'EL PLAYÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68264,
    'Nombre Departamento': 'SANTANDER',
    'text': 'ENCINO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68266,
    'Nombre Departamento': 'SANTANDER',
    'text': 'ENCISO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68271,
    'Nombre Departamento': 'SANTANDER',
    'text': 'FLORIÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68276,
    'Nombre Departamento': 'SANTANDER',
    'text': 'FLORIDABLANCA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68296,
    'Nombre Departamento': 'SANTANDER',
    'text': 'GALÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68298,
    'Nombre Departamento': 'SANTANDER',
    'text': 'GÁMBITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68307,
    'Nombre Departamento': 'SANTANDER',
    'text': 'GIRÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68318,
    'Nombre Departamento': 'SANTANDER',
    'text': 'GUACA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68320,
    'Nombre Departamento': 'SANTANDER',
    'text': 'GUADALUPE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68322,
    'Nombre Departamento': 'SANTANDER',
    'text': 'GUAPOTÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68324,
    'Nombre Departamento': 'SANTANDER',
    'text': 'GUAVATÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68327,
    'Nombre Departamento': 'SANTANDER',
    'text': 'GÜEPSA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68344,
    'Nombre Departamento': 'SANTANDER',
    'text': 'HATO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68368,
    'Nombre Departamento': 'SANTANDER',
    'text': 'JESÚS MARÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68370,
    'Nombre Departamento': 'SANTANDER',
    'text': 'JORDÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68377,
    'Nombre Departamento': 'SANTANDER',
    'text': 'LA BELLEZA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68385,
    'Nombre Departamento': 'SANTANDER',
    'text': 'LANDÁZURI',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68397,
    'Nombre Departamento': 'SANTANDER',
    'text': 'LA PAZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68406,
    'Nombre Departamento': 'SANTANDER',
    'text': 'LEBRIJA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68418,
    'Nombre Departamento': 'SANTANDER',
    'text': 'LOS SANTOS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68425,
    'Nombre Departamento': 'SANTANDER',
    'text': 'MACARAVITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68432,
    'Nombre Departamento': 'SANTANDER',
    'text': 'MÁLAGA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68444,
    'Nombre Departamento': 'SANTANDER',
    'text': 'MATANZA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68464,
    'Nombre Departamento': 'SANTANDER',
    'text': 'MOGOTES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68468,
    'Nombre Departamento': 'SANTANDER',
    'text': 'MOLAGAVITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68498,
    'Nombre Departamento': 'SANTANDER',
    'text': 'OCAMONTE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68500,
    'Nombre Departamento': 'SANTANDER',
    'text': 'OIBA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68502,
    'Nombre Departamento': 'SANTANDER',
    'text': 'ONZAGA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68522,
    'Nombre Departamento': 'SANTANDER',
    'text': 'PALMAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68524,
    'Nombre Departamento': 'SANTANDER',
    'text': 'PALMAS DEL SOCORRO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68533,
    'Nombre Departamento': 'SANTANDER',
    'text': 'PÁRAMO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68547,
    'Nombre Departamento': 'SANTANDER',
    'text': 'PIEDECUESTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68549,
    'Nombre Departamento': 'SANTANDER',
    'text': 'PINCHOTE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68572,
    'Nombre Departamento': 'SANTANDER',
    'text': 'PUENTE NACIONAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68573,
    'Nombre Departamento': 'SANTANDER',
    'text': 'PUERTO PARRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68575,
    'Nombre Departamento': 'SANTANDER',
    'text': 'PUERTO WILCHES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68615,
    'Nombre Departamento': 'SANTANDER',
    'text': 'RIONEGRO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68655,
    'Nombre Departamento': 'SANTANDER',
    'text': 'SABANA DE TORRES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68669,
    'Nombre Departamento': 'SANTANDER',
    'text': 'SAN ANDRÉS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68673,
    'Nombre Departamento': 'SANTANDER',
    'text': 'SAN BENITO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68679,
    'Nombre Departamento': 'SANTANDER',
    'text': 'SAN GIL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68682,
    'Nombre Departamento': 'SANTANDER',
    'text': 'SAN JOAQUÍN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68684,
    'Nombre Departamento': 'SANTANDER',
    'text': 'SAN JOSÉ DE MIRANDA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68686,
    'Nombre Departamento': 'SANTANDER',
    'text': 'SAN MIGUEL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68689,
    'Nombre Departamento': 'SANTANDER',
    'text': 'SAN VICENTE DE CHUCURÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68705,
    'Nombre Departamento': 'SANTANDER',
    'text': 'SANTA BÁRBARA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68720,
    'Nombre Departamento': 'SANTANDER',
    'text': 'SANTA HELENA DEL OPÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68745,
    'Nombre Departamento': 'SANTANDER',
    'text': 'SIMACOTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68755,
    'Nombre Departamento': 'SANTANDER',
    'text': 'SOCORRO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68770,
    'Nombre Departamento': 'SANTANDER',
    'text': 'SUAITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68773,
    'Nombre Departamento': 'SANTANDER',
    'text': 'SUCRE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68780,
    'Nombre Departamento': 'SANTANDER',
    'text': 'SURATÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68820,
    'Nombre Departamento': 'SANTANDER',
    'text': 'TONA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68855,
    'Nombre Departamento': 'SANTANDER',
    'text': 'VALLE DE SAN JOSÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68861,
    'Nombre Departamento': 'SANTANDER',
    'text': 'VÉLEZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68867,
    'Nombre Departamento': 'SANTANDER',
    'text': 'VETAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68872,
    'Nombre Departamento': 'SANTANDER',
    'text': 'VILLANUEVA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 68,
    'value': 68895,
    'Nombre Departamento': 'SANTANDER',
    'text': 'ZAPATOCA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70001,
    'Nombre Departamento': 'SUCRE',
    'text': 'SINCELEJO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70110,
    'Nombre Departamento': 'SUCRE',
    'text': 'BUENAVISTA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70124,
    'Nombre Departamento': 'SUCRE',
    'text': 'CAIMITO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70204,
    'Nombre Departamento': 'SUCRE',
    'text': 'COLOSÓ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70215,
    'Nombre Departamento': 'SUCRE',
    'text': 'COROZAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70221,
    'Nombre Departamento': 'SUCRE',
    'text': 'COVEÑAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70230,
    'Nombre Departamento': 'SUCRE',
    'text': 'CHALÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70233,
    'Nombre Departamento': 'SUCRE',
    'text': 'EL ROBLE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70235,
    'Nombre Departamento': 'SUCRE',
    'text': 'GALERAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70265,
    'Nombre Departamento': 'SUCRE',
    'text': 'GUARANDA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70400,
    'Nombre Departamento': 'SUCRE',
    'text': 'LA UNIÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70418,
    'Nombre Departamento': 'SUCRE',
    'text': 'LOS PALMITOS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70429,
    'Nombre Departamento': 'SUCRE',
    'text': 'MAJAGUAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70473,
    'Nombre Departamento': 'SUCRE',
    'text': 'MORROA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70508,
    'Nombre Departamento': 'SUCRE',
    'text': 'OVEJAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70523,
    'Nombre Departamento': 'SUCRE',
    'text': 'PALMITO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70670,
    'Nombre Departamento': 'SUCRE',
    'text': 'SAMPUÉS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70678,
    'Nombre Departamento': 'SUCRE',
    'text': 'SAN BENITO ABAD',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70702,
    'Nombre Departamento': 'SUCRE',
    'text': 'SAN JUAN DE BETULIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70708,
    'Nombre Departamento': 'SUCRE',
    'text': 'SAN MARCOS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70713,
    'Nombre Departamento': 'SUCRE',
    'text': 'SAN ONOFRE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70717,
    'Nombre Departamento': 'SUCRE',
    'text': 'SAN PEDRO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70742,
    'Nombre Departamento': 'SUCRE',
    'text': 'SAN LUIS DE SINCÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70771,
    'Nombre Departamento': 'SUCRE',
    'text': 'SUCRE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70820,
    'Nombre Departamento': 'SUCRE',
    'text': 'SANTIAGO DE TOLÚ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 70,
    'value': 70823,
    'Nombre Departamento': 'SUCRE',
    'text': 'SAN JOSÉ DE TOLUVIEJO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73001,
    'Nombre Departamento': 'TOLIMA',
    'text': 'IBAGUÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73024,
    'Nombre Departamento': 'TOLIMA',
    'text': 'ALPUJARRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73026,
    'Nombre Departamento': 'TOLIMA',
    'text': 'ALVARADO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73030,
    'Nombre Departamento': 'TOLIMA',
    'text': 'AMBALEMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73043,
    'Nombre Departamento': 'TOLIMA',
    'text': 'ANZOÁTEGUI',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73055,
    'Nombre Departamento': 'TOLIMA',
    'text': 'ARMERO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73067,
    'Nombre Departamento': 'TOLIMA',
    'text': 'ATACO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73124,
    'Nombre Departamento': 'TOLIMA',
    'text': 'CAJAMARCA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73148,
    'Nombre Departamento': 'TOLIMA',
    'text': 'CARMEN DE APICALÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73152,
    'Nombre Departamento': 'TOLIMA',
    'text': 'CASABIANCA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73168,
    'Nombre Departamento': 'TOLIMA',
    'text': 'CHAPARRAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73200,
    'Nombre Departamento': 'TOLIMA',
    'text': 'COELLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73217,
    'Nombre Departamento': 'TOLIMA',
    'text': 'COYAIMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73226,
    'Nombre Departamento': 'TOLIMA',
    'text': 'CUNDAY',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73236,
    'Nombre Departamento': 'TOLIMA',
    'text': 'DOLORES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73268,
    'Nombre Departamento': 'TOLIMA',
    'text': 'ESPINAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73270,
    'Nombre Departamento': 'TOLIMA',
    'text': 'FALAN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73275,
    'Nombre Departamento': 'TOLIMA',
    'text': 'FLANDES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73283,
    'Nombre Departamento': 'TOLIMA',
    'text': 'FRESNO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73319,
    'Nombre Departamento': 'TOLIMA',
    'text': 'GUAMO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73347,
    'Nombre Departamento': 'TOLIMA',
    'text': 'HERVEO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73349,
    'Nombre Departamento': 'TOLIMA',
    'text': 'HONDA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73352,
    'Nombre Departamento': 'TOLIMA',
    'text': 'ICONONZO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73408,
    'Nombre Departamento': 'TOLIMA',
    'text': 'LÉRIDA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73411,
    'Nombre Departamento': 'TOLIMA',
    'text': 'LÍBANO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73443,
    'Nombre Departamento': 'TOLIMA',
    'text': 'SAN SEBASTIÁN DE MARIQUITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73449,
    'Nombre Departamento': 'TOLIMA',
    'text': 'MELGAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73461,
    'Nombre Departamento': 'TOLIMA',
    'text': 'MURILLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73483,
    'Nombre Departamento': 'TOLIMA',
    'text': 'NATAGAIMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73504,
    'Nombre Departamento': 'TOLIMA',
    'text': 'ORTEGA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73520,
    'Nombre Departamento': 'TOLIMA',
    'text': 'PALOCABILDO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73547,
    'Nombre Departamento': 'TOLIMA',
    'text': 'PIEDRAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73555,
    'Nombre Departamento': 'TOLIMA',
    'text': 'PLANADAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73563,
    'Nombre Departamento': 'TOLIMA',
    'text': 'PRADO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73585,
    'Nombre Departamento': 'TOLIMA',
    'text': 'PURIFICACIÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73616,
    'Nombre Departamento': 'TOLIMA',
    'text': 'RIOBLANCO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73622,
    'Nombre Departamento': 'TOLIMA',
    'text': 'RONCESVALLES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73624,
    'Nombre Departamento': 'TOLIMA',
    'text': 'ROVIRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73671,
    'Nombre Departamento': 'TOLIMA',
    'text': 'SALDAÑA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73675,
    'Nombre Departamento': 'TOLIMA',
    'text': 'SAN ANTONIO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73678,
    'Nombre Departamento': 'TOLIMA',
    'text': 'SAN LUIS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73686,
    'Nombre Departamento': 'TOLIMA',
    'text': 'SANTA ISABEL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73770,
    'Nombre Departamento': 'TOLIMA',
    'text': 'SUÁREZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73854,
    'Nombre Departamento': 'TOLIMA',
    'text': 'VALLE DE SAN JUAN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73861,
    'Nombre Departamento': 'TOLIMA',
    'text': 'VENADILLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73870,
    'Nombre Departamento': 'TOLIMA',
    'text': 'VILLAHERMOSA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 73,
    'value': 73873,
    'Nombre Departamento': 'TOLIMA',
    'text': 'VILLARRICA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76001,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'CALI',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76020,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'ALCALÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76036,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'ANDALUCÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76041,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'ANSERMANUEVO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76054,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'ARGELIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76100,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'BOLÍVAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76109,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'BUENAVENTURA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76111,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'GUADALAJARA DE BUGA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76113,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'BUGALAGRANDE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76122,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'CAICEDONIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76126,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'CALIMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76130,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'CANDELARIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76147,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'CARTAGO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76233,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'DAGUA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76243,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'EL ÁGUILA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76246,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'EL CAIRO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76248,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'EL CERRITO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76250,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'EL DOVIO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76275,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'FLORIDA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76306,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'GINEBRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76318,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'GUACARÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76364,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'JAMUNDÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76377,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'LA CUMBRE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76400,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'LA UNIÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76403,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'LA VICTORIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76497,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'OBANDO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76520,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'PALMIRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76563,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'PRADERA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76606,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'RESTREPO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76616,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'RIOFRÍO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76622,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'ROLDANILLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76670,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'SAN PEDRO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76736,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'SEVILLA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76823,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'TORO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76828,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'TRUJILLO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76834,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'TULUÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76845,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'ULLOA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76863,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'VERSALLES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76869,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'VIJES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76890,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'YOTOCO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76892,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'YUMBO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 76,
    'value': 76895,
    'Nombre Departamento': 'VALLE DEL CAUCA',
    'text': 'ZARZAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 81,
    'value': 81001,
    'Nombre Departamento': 'ARAUCA',
    'text': 'ARAUCA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 81,
    'value': 81065,
    'Nombre Departamento': 'ARAUCA',
    'text': 'ARAUQUITA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 81,
    'value': 81220,
    'Nombre Departamento': 'ARAUCA',
    'text': 'CRAVO NORTE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 81,
    'value': 81300,
    'Nombre Departamento': 'ARAUCA',
    'text': 'FORTUL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 81,
    'value': 81591,
    'Nombre Departamento': 'ARAUCA',
    'text': 'PUERTO RONDÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 81,
    'value': 81736,
    'Nombre Departamento': 'ARAUCA',
    'text': 'SARAVENA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 81,
    'value': 81794,
    'Nombre Departamento': 'ARAUCA',
    'text': 'TAME',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85001,
    'Nombre Departamento': 'CASANARE',
    'text': 'YOPAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85010,
    'Nombre Departamento': 'CASANARE',
    'text': 'AGUAZUL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85015,
    'Nombre Departamento': 'CASANARE',
    'text': 'CHÁMEZA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85125,
    'Nombre Departamento': 'CASANARE',
    'text': 'HATO COROZAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85136,
    'Nombre Departamento': 'CASANARE',
    'text': 'LA SALINA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85139,
    'Nombre Departamento': 'CASANARE',
    'text': 'MANÍ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85162,
    'Nombre Departamento': 'CASANARE',
    'text': 'MONTERREY',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85225,
    'Nombre Departamento': 'CASANARE',
    'text': 'NUNCHÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85230,
    'Nombre Departamento': 'CASANARE',
    'text': 'OROCUÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85250,
    'Nombre Departamento': 'CASANARE',
    'text': 'PAZ DE ARIPORO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85263,
    'Nombre Departamento': 'CASANARE',
    'text': 'PORE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85279,
    'Nombre Departamento': 'CASANARE',
    'text': 'RECETOR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85300,
    'Nombre Departamento': 'CASANARE',
    'text': 'SABANALARGA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85315,
    'Nombre Departamento': 'CASANARE',
    'text': 'SÁCAMA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85325,
    'Nombre Departamento': 'CASANARE',
    'text': 'SAN LUIS DE PALENQUE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85400,
    'Nombre Departamento': 'CASANARE',
    'text': 'TÁMARA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85410,
    'Nombre Departamento': 'CASANARE',
    'text': 'TAURAMENA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85430,
    'Nombre Departamento': 'CASANARE',
    'text': 'TRINIDAD',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 85,
    'value': 85440,
    'Nombre Departamento': 'CASANARE',
    'text': 'VILLANUEVA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 86,
    'value': 86001,
    'Nombre Departamento': 'PUTUMAYO',
    'text': 'MOCOA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 86,
    'value': 86219,
    'Nombre Departamento': 'PUTUMAYO',
    'text': 'COLÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 86,
    'value': 86320,
    'Nombre Departamento': 'PUTUMAYO',
    'text': 'ORITO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 86,
    'value': 86568,
    'Nombre Departamento': 'PUTUMAYO',
    'text': 'PUERTO ASÍS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 86,
    'value': 86569,
    'Nombre Departamento': 'PUTUMAYO',
    'text': 'PUERTO CAICEDO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 86,
    'value': 86571,
    'Nombre Departamento': 'PUTUMAYO',
    'text': 'PUERTO GUZMÁN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 86,
    'value': 86573,
    'Nombre Departamento': 'PUTUMAYO',
    'text': 'PUERTO LEGUÍZAMO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 86,
    'value': 86749,
    'Nombre Departamento': 'PUTUMAYO',
    'text': 'SIBUNDOY',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 86,
    'value': 86755,
    'Nombre Departamento': 'PUTUMAYO',
    'text': 'SAN FRANCISCO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 86,
    'value': 86757,
    'Nombre Departamento': 'PUTUMAYO',
    'text': 'SAN MIGUEL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 86,
    'value': 86760,
    'Nombre Departamento': 'PUTUMAYO',
    'text': 'SANTIAGO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 86,
    'value': 86865,
    'Nombre Departamento': 'PUTUMAYO',
    'text': 'VALLE DEL GUAMUEZ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 86,
    'value': 86885,
    'Nombre Departamento': 'PUTUMAYO',
    'text': 'VILLAGARZÓN',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 88,
    'value': 88001,
    'Nombre Departamento': 'ARCHIPIÉLAGO DE SAN ANDRÉS. PROVIDENCIA Y SANTA CATALINA',
    'text': 'SAN ANDRÉS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Isla',
  },
  {
    'Código Departamento': 88,
    'value': 88564,
    'Nombre Departamento': 'ARCHIPIÉLAGO DE SAN ANDRÉS. PROVIDENCIA Y SANTA CATALINA',
    'text': 'PROVIDENCIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 91,
    'value': 91001,
    'Nombre Departamento': 'AMAZONAS',
    'text': 'LETICIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 91,
    'value': 91263,
    'Nombre Departamento': 'AMAZONAS',
    'text': 'EL ENCANTO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 91,
    'value': 91405,
    'Nombre Departamento': 'AMAZONAS',
    'text': 'LA CHORRERA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 91,
    'value': 91407,
    'Nombre Departamento': 'AMAZONAS',
    'text': 'LA PEDRERA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 91,
    'value': 91430,
    'Nombre Departamento': 'AMAZONAS',
    'text': 'LA VICTORIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 91,
    'value': 91460,
    'Nombre Departamento': 'AMAZONAS',
    'text': 'MIRITÍ - PARANÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 91,
    'value': 91530,
    'Nombre Departamento': 'AMAZONAS',
    'text': 'PUERTO ALEGRÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 91,
    'value': 91536,
    'Nombre Departamento': 'AMAZONAS',
    'text': 'PUERTO ARICA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 91,
    'value': 91540,
    'Nombre Departamento': 'AMAZONAS',
    'text': 'PUERTO NARIÑO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 91,
    'value': 91669,
    'Nombre Departamento': 'AMAZONAS',
    'text': 'PUERTO SANTANDER',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 91,
    'value': 91798,
    'Nombre Departamento': 'AMAZONAS',
    'text': 'TARAPACÁ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 94,
    'value': 94001,
    'Nombre Departamento': 'GUAINÍA',
    'text': 'INÍRIDA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 94,
    'value': 94343,
    'Nombre Departamento': 'GUAINÍA',
    'text': 'BARRANCOMINAS',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 94,
    'value': 94883,
    'Nombre Departamento': 'GUAINÍA',
    'text': 'SAN FELIPE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 94,
    'value': 94884,
    'Nombre Departamento': 'GUAINÍA',
    'text': 'PUERTO COLOMBIA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 94,
    'value': 94885,
    'Nombre Departamento': 'GUAINÍA',
    'text': 'LA GUADALUPE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 94,
    'value': 94886,
    'Nombre Departamento': 'GUAINÍA',
    'text': 'CACAHUAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 94,
    'value': 94887,
    'Nombre Departamento': 'GUAINÍA',
    'text': 'PANA PANA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 94,
    'value': 94888,
    'Nombre Departamento': 'GUAINÍA',
    'text': 'MORICHAL',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 95,
    'value': 95001,
    'Nombre Departamento': 'GUAVIARE',
    'text': 'SAN JOSÉ DEL GUAVIARE',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 95,
    'value': 95015,
    'Nombre Departamento': 'GUAVIARE',
    'text': 'CALAMAR',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 95,
    'value': 95025,
    'Nombre Departamento': 'GUAVIARE',
    'text': 'EL RETORNO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 95,
    'value': 95200,
    'Nombre Departamento': 'GUAVIARE',
    'text': 'MIRAFLORES',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 97,
    'value': 97001,
    'Nombre Departamento': 'VAUPÉS',
    'text': 'MITÚ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 97,
    'value': 97161,
    'Nombre Departamento': 'VAUPÉS',
    'text': 'CARURÚ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 97,
    'value': 97511,
    'Nombre Departamento': 'VAUPÉS',
    'text': 'PACOA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 97,
    'value': 97666,
    'Nombre Departamento': 'VAUPÉS',
    'text': 'TARAIRA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 97,
    'value': 97777,
    'Nombre Departamento': 'VAUPÉS',
    'text': 'PAPUNAHUA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 97,
    'value': 97889,
    'Nombre Departamento': 'VAUPÉS',
    'text': 'YAVARATÉ',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Área no municipalizada',
  },
  {
    'Código Departamento': 99,
    'value': 99001,
    'Nombre Departamento': 'VICHADA',
    'text': 'PUERTO CARREÑO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 99,
    'value': 99524,
    'Nombre Departamento': 'VICHADA',
    'text': 'LA PRIMAVERA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 99,
    'value': 99624,
    'Nombre Departamento': 'VICHADA',
    'text': 'SANTA ROSALÍA',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 99,
    'value': 99773,
    'Nombre Departamento': 'VICHADA',
    'text': 'CUMARIBO',
    'Tipo: Municipio / Isla / Área no municipalizada': 'Municipio',
  },
  {
    'Código Departamento': 'Fuente: DANE.',
    'value': '',
    'Nombre Departamento': '',
    'text': '',
    'Tipo: Municipio / Isla / Área no municipalizada': '',
  },
  {
    'Código Departamento': 'Nota: En la actualidad el país se compone de 1.102 municipios. 18 Áreas no municipalizadas y la Isla de San Andrés.',
    'value': '',
    'Nombre Departamento': '',
    'text': '',
    'Tipo: Municipio / Isla / Área no municipalizada': '',
  },
  {
    'Código Departamento': 'Actualizado al: 10 de Junio de 2020',
    'value': '',
    'Nombre Departamento': '',
    'text': '',
    'Tipo: Municipio / Isla / Área no municipalizada': '',
  },
  {
    'Código Departamento': '*Desde el 1 de diciembre de 2019 comenzó a regir la Ordenanza 248 de 2019 de la Asamblea Departamental de Guainía que crea el municipio de Barrancominas.',
    'value': '',
    'Nombre Departamento': '',
    'text': '',
    'Tipo: Municipio / Isla / Área no municipalizada': '',
  },
  {
    'Código Departamento': 'conformado por las áreas no municipalizadas de Barrancominas y Mapiripana. Se incorporó en la DIVIPOLA en Mayo de 2020 una vez definida la información geográfica por el IGAC.',
    'value': '',
    'Nombre Departamento': '',
    'text': '',
    'Tipo: Municipio / Isla / Área no municipalizada': '',
  },
]
