<template lang="html">
  <div>
    <h2>Crear Ticket</h2>
    <br>
    <form>
      
          <!-- basic -->
          <b-form-group
            label="Tipo de usuario"
            label-for="ticket_type_id"
          >
            <b-form-select
              id="ticket_type_id"
              v-model="client_type"
              disabled
              :options="clientTypes"
            />
          </b-form-group>
          <!-- basic -->
          <b-form-group
            :label="client_type !== 3 ? 'Cuenta' : 'Nombre de la compañia'"
            label-for="account"
          >
            <b-form-input
              id="account"
              v-model="contactInfo.account.name || ''"
              disabled
              placeholder="Cuenta"
            />
          </b-form-group>

          <!-- basic -->
          <b-form-group
            :label="client_type !== 3 ? 'Contacto' : 'Contacto cliente'"
            label-for="contact"
          >
            <b-form-input
              id="contact"
              v-model="contactInfo.firstname + ' ' + contactInfo.lastname || ''"
              disabled
              placeholder="Contacto"
            />
          </b-form-group>

          <!-- button on right -->
          <b-input-group>
            <b-form-group
              style="width: 80%;"
            >
              <b-form-input
                v-if="istSearchDisAccounts"
                id="searchAccount"
                v-model="accountDis"
                placeholder="Cuenta Distribuidora"
              />
              <b-form-select
                v-if="!istSearchDisAccounts"
                id="setAccount"
                v-model="account_idLocal"
                placeholder="Seleccione la compañia"
                :options="DisAccounts"
              />
            </b-form-group>
            <b-input-group-append
              style="
                height: 38px;
                width: 20%;
            "
            >
              <b-button
                v-if="istSearchDisAccounts"
                variant="outline-primary"
                style="
                  padding: 4%;
                  width: 100%;
              "
                @click="searchDisAccounts(true)"
              >
                <feather-icon
                  icon="SearchIcon"
                />
              </b-button>
              <b-button
                v-if="!istSearchDisAccounts"
                variant="outline-primary"
                style="
                  padding: 4%;
                  width: 100%;
              "
                @click="searchDisAccounts(false)"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <b-input-group>
            <b-form-group
              style="width: 80%;"
            >
              <b-form-input
                v-if="istSearchAllyAccounts"
                id="searchAccount"
                v-model="accountAli"
                placeholder="Cuenta Aliado"
              />
              <b-form-select
                v-if="!istSearchAllyAccounts"
                id="setAccountAli"
                v-model="accountAli_idLocal"
                placeholder="Seleccione la compañia"
                :options="allyAccounts"
              />
            </b-form-group>
            <b-input-group-append
              style="
                height: 38px;
                width: 20%;
            "
            >
              <b-button
                v-if="istSearchAllyAccounts"
                variant="outline-primary"
                style="
                  padding: 4%;
                  width: 100%;
              "
                @click="searchAllyAccounts(true)"
              >
                <feather-icon
                  icon="SearchIcon"
                />
              </b-button>
              <b-button
                v-if="!istSearchAllyAccounts"
                variant="outline-primary"
                style="
                  padding: 4%;
                  width: 100%;
              "
                @click="searchAllyAccounts(false)"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>

        <!-- Types -->

          <!-- basic -->
          <b-form-group
            label="Tipo de soporte"
            label-for="support_type_id"
          >
            <b-form-select
              id="support_type_id"
              v-model="support_type_id"
              :options="supportTypes"
            />
          </b-form-group>
          <!-- basic -->
          <b-form-group
            label="Tipo de caso"
            label-for="ticket_type_id"
          >
            <b-form-select
              id="ticket_type_id"
              v-model="ticket_type_id"
              :options="ticketTypes"
            />
          </b-form-group>
          <!-- basic -->
          <b-form-group
            label="Descripción"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              placeholder="Descripción"
              rows="3"
            />
          </b-form-group>

      <!--Start: Form Actions-->
          <b-col style="display: flex; justify-content: center">
            <b-button
              variant="primary"
              style="width: 40%; margin-top: 3%"
              @click="createTicket()"
              class="mr-1"
            >
              {{ "Crear" }}
            </b-button>
            <b-button
              variant="outline-secondary"
              style="width: 40%;margin-top: 3%;"
              @click="$emit('close-ticket-form')"
              class="mr-1"
            >
              {{ 'Cancelar' }}
            </b-button>
          </b-col>
      <!--End: Form Actions-->
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BButton,
  BFormSelect,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import { clientTypes } from "../../codesData/clientTypes";

export default {
  name:'TicketFormVue',
  components: {
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BButton,
    BFormSelect,
    BFormTextarea,
    BBadge,
  },
  props: {},
  data() {
    const data = {
      client_type: 3,
      account: "",
      contact: "",
      accountDis: "",
      accountAli: "",
      support_type_id: "",
      ticket_type_id: "",
      description: "",
      file: "",
      files: [],
      clientTypes,
      account_idLocal: "",
      accountAli_idLocal: "",
    };

    return data;
  },

  mounted() {
    if (
      this.contactInfo.account_id &&
      this.contactInfo.account.id_account_type
    ) {
      // eslint-disable-next-line radix
      this.client_type = parseInt(this.contactInfo.account.id_account_type);
    } else {
      this.client_type = 3;
    }
    const payloadSearch = {
      isTicket: true,
      isSearch: true,
    };
    const payload = {
      isTicket: true,
      value: [],
    };
    this.support_type_id = this.supportTypes[0].value;
    this.ticket_type_id = this.ticketTypes[0].value;
    this.$store.dispatch("contact/setItsSearchAccounts", payloadSearch);
    this.$store.dispatch("contact/setAccounts", payload);
  },
  computed: {
    ...mapState({
      supportTypes: state => state.ticket.supportTypes,
      contactInfo: state => state.contact.contactInfo,
      ticketTypes: state => state.ticket.ticketTypes,
      contact_id: state => state.contact.contactInfo.contact_id,
      contactName: state =>
        `${state.contact.contactInfo.firstname} ${state.contact.contactInfo.lastname}`,
      DisAccounts: state => state.ticket.DisAccounts,
      allyAccounts: state => state.ticket.allyAccounts,
      istSearchDisAccounts: state => state.ticket.istSearchDisAccounts,
      istSearchAllyAccounts: state => state.ticket.istSearchAllyAccounts,
    }),
  },
  methods: {
    async createTicket() {
      const agentId = await JSON.parse(localStorage.getItem("agentInfo")).agent_id;
      const payload = {
        ticketDetails: {
          agent_id: agentId,
          ticket_type_id: this.ticket_type_id,
          support_type_id: this.support_type_id,
          description: this.description,
          contact_id: this.contactInfo.id,
          account_partner_id:
            this.client_type === 3
              ? this.account_idLocal
              : this.contactInfo.account_id,
          account_client_id:
            this.client_type === 3 ? this.contactInfo.account_id : "",
          allie_client_id: this.accountAli_idLocal
        },
        files: this.files,
        toast: this.$toast,
      };
      this.$store.dispatch("ticket/createTicket", payload);
      this.$emit('close-ticket-form')
    },
    async addFiles(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const document = {
          name: file.name,
          base64: reader.result,
        };
        this.files.push(document);
      };
      reader.readAsDataURL(file);
    },
    deleteFiles(index) {
      this.files.splice(index, 1);
    },
    cutString(string) {
      let newString = string;
      if (string.length > 16) {
        newString = `${string.slice(0, 13)} ...`;
      }
      return newString;
    },
    searchDisAccounts(bool) {
      if (bool) {
        if (!this.accountDis) {
          return;
        }
        const payload = {
          query: this.accountDis,
          toast: this.$toast,
          isTicket: true,
        };
        this.$store.dispatch("contact/searchDisAccounts", payload);
        this.accountDis = "";
        this.account_idLocal = "";
      } else {
        const payloadSearch = {
          isTicket: true,
          ally:false,
          isSearch: true,
        };
        const payload = {
          isTicket: true,
          ally:false,
          value: [],
        };
        this.$store.dispatch("contact/setItsSearchAccounts", payloadSearch);
        this.$store.dispatch("contact/setAccounts", payload);
      }
    },
    searchAllyAccounts(bool) {
      if (bool) {
        if (!this.accountAli) {
          return;
        }
        const payload = {
          query: this.accountAli,
          toast: this.$toast,
          isTicket: true,
        };
        this.$store.dispatch("contact/searchAllyAccounts", payload);
        this.accountAli = "";
        this.accountAli_idLocal = "";
      } else {
        const payloadSearch = {
          isTicket: true,
          ally:true,
          isSearch: true,
        };
        const payload = {
          isTicket: true,
          ally:true,
          value: [],
        };
        this.$store.dispatch("contact/setItsSearchAccounts", payloadSearch);
        this.$store.dispatch("contact/setAccounts", payload);
      }
    },
  },
};
</script>
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  