<template lang="html">
  <div>
    <h2>Crear Cuenta</h2>
    <br>
    <form>
      
          <!-- basic -->
          <b-form-group
            label="Nombre"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="name"
              placeholder="Nombre"
            />
          </b-form-group>

          <!-- basic -->
          <b-form-group
            label="NIT"
            label-for="nit"
          >
            <b-form-input
              id="nit"
              v-model="nit"
              placeholder="NIT"
            />
          </b-form-group>
          <!-- basic -->
          <b-form-group
            label="Tipo"
            label-for="type"
          >
            <b-form-select
              id="type"
              v-model="type"
              :options="clientTypes"
            />
          </b-form-group>

          <!-- basic -->
          <b-form-group
            label="Departamento"
            label-for="state"
          >
            <b-form-select
              id="state"
              v-model="state"
              :options="stateCodes"
              @change="filterCity"
            />
          </b-form-group>

          <!-- basic -->
          <b-form-group
            label="Ciudad"
            label-for="city"
          >
            <b-form-select
              id="city"
              v-model="city"
              :options="citys"
            />
          </b-form-group>

      <!--Start: Form Actions-->
          <b-col style="display: flex; justify-content: center">
            <b-button
              variant="primary"
              style="width: 40%; margin-top: 3%"
              @click="createAccount()"
              class="mr-1"
            >
              {{ "Crear" }}
            </b-button>
            <b-button
              variant="outline-secondary"
              style="width: 40%;margin-top: 3%;"
              @click="$emit('close-account-form')"
              class="mr-1"
            >
              {{ 'Cancelar' }}
            </b-button>
          </b-col>
    <!--End: Form Actions-->

    </form>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import {
    BCol,
    BBadge,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BCard,
  } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { cityCodes } from '../../codesData/cityCodes'
  import { stateCodes } from '../../codesData/stateCodes'
  import { clientTypes } from '../../codesData/clientTypes'

  export default {
    name:'AccountFormVue',
    components: {
      BCol,
      BBadge,
      BCard,
      BFormGroup,
      BFormInput,
      BButton,
      BFormSelect,

    },
    props: {

    },
    data() {
      const data = {
        name: '',
        nit: '',
        type: 3,
        country: 'COL',
        state: '05',
        city: '05001',
        cityCodes,
        stateCodes,
        clientTypes,
        citys: [],
      }
      return data
    },

    async mounted() {
      this.filterCity()
    },
    computed: {
      ...mapState({

      }),
    },
    watch: {},
    methods: {
      createAccount() {
        if (!this.name || !this.nit) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Debe completar todos los campos requeridos',
              icon: 'EditIcon',
              variant: 'error',
            },
          })
          return
        }
        const payload = {
          accountDetails: {
            name: this.name,
            nit: this.nit,
            type: this.type,
            country: this.country,
            state: this.state,
            city: this.city,
          },
          toast: this.$toast,
        }
        this.$store.dispatch('account/createAccount', payload)
        this.$emit('close-account-form')
      },
      async filterCity() {
        this.citys = await this.cityCodes.filter(city => this.state === city['Código Departamento'])
        if (this.citys && this.citys[0] && this.citys[0].value) {
          this.city = this.citys[0].value
        }
      },
    },
  }
</script>