<template>
    <b-card
      :img-src="require('@core/assets/images/spellty-timeto.jpg')"
      img-alt="Profile Cover Photo"
      img-top
      class="card-profile"
    >
      <h2 style="margin-bottom: 5%">
        Bienvenid@
      </h2>
      <div class="profile-image-wrapper">
        <div class="profile-image p-0">
          <b-avatar
            size="114"
            variant="light"
            :src="photo"
          />
        </div>
      </div>
      <h3>{{ fullName }}</h3>
      <h6 class="text-muted">
        {{ profile.email }}
      </h6>
      <b-badge
        class="profile-badge"
        variant="light-primary"
      >
      </b-badge>
    </b-card>
  </template>
  <script>
  import {
    BCard,
    BBadge,
    BAvatar,
  } from 'bootstrap-vue'
  
  export default {
    name: 'Welcome',
    components: {
      BBadge,
      BCard,
      BAvatar,
  
    },
    data() {
      return {
        profile: {},
        fullName: '',
        photo: '../icons/logoEpika-128X128.png',
        appVersion: '',
        mobileOpt1: null,
        isGroup: false,
        messageUnsupp: '',
        titleUnsupp: '',
        mobileFormatInternational: '',
        mobile: '',
      }
    },
    mounted() {
      const agentInfo = localStorage.getItem('agentInfo')
        ? JSON.parse(localStorage.getItem('agentInfo'))
        : null
  
      this.profile = agentInfo
      this.fullName = `${agentInfo.name} ${agentInfo.last_name}`
  
    },
  
    methods: {

    },
    
  }
  </script>
  <style scoped lang="scss">
  .card-profile{
    height: 100%;
    padding: 0;
  }
  </style>
  