<template>
  <b-overlay
    :show="timeToChangeOverlay"
    variant="white"
    opacity= 0.9
  >
    <div style="padding: 1.8rem;">
      <div v-if="!createTicket && !createAccount && !ticketsList">
        <h2>{{ isFormAddData ? 'Crear Contacto' : 'Contacto' }}</h2>
        <b-col v-if="isContactInfo" style="display: flex; justify-content: center">
          <b-button
            variant="primary"
            style="width: 45%; margin-top: 3%; margin-bottom: 3%;"
            size="sm"
            @click="showTicketForm()"
            class="mr-1"
          >
            {{ "Crear Tickets" }}
          </b-button>
          <b-button
            variant="outline-secondary"
            style="width: 45%;margin-top: 3%; margin-bottom: 3%;"
            size="sm"
            @click="showTicketsList()"
            class="mr-1"
          >
            {{ 'Ver Tickets' }}
          </b-button>
        </b-col>
        <div >
          <tabs-lead-contact
            v-if="isFormAddData"
          />
          <tabs-lead-contact
            v-if="isContactInfo"
            @show-contact-form="showAccountForm()"
          />
          
        </div>
      </div>
      <ticket-form 
      @close-ticket-form = "showTicketForm()"
      v-if="createTicket"/>
      <account-form
      @close-account-form ="showAccountForm()"
      v-if="createAccount"
      />
      <tickets-list v-if="ticketsList"
      @close-ticket-list = "showTicketsList()"/>
    </div>
  </b-overlay>
</template>


<script>

import {
  BButton, BSidebar, BCardText,BCard,BBadge,BAvatar,BLink, BOverlay, BCol
} from 'bootstrap-vue'
import ContactForm from './ContactForm.vue'
import { mapState } from 'vuex'
import TicketFormVue from './TicketForm.vue'
import AccountFormVue from './AccountForm.vue'
import TicketsVue from './TicketList.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'TimeToChangeRightbar',
  components: {
    VuePerfectScrollbar,
    BBadge,
    BCard,
    BAvatar,
    BButton,
    BSidebar,
    BCardText,
    BLink,
    BOverlay,
    BCol,
    'tabs-lead-contact':ContactForm,
    'ticket-form':TicketFormVue,
    'account-form':AccountFormVue,
    'tickets-list': TicketsVue
  },
  mounted() {
  },
  data() {
    return {
      mobile: '',
      mobileFormatInternational: '',
      isGroup: false,
      mobileOpt1: null,
      mobileOpt2: null,
      titleUnsupp: '',
      messageUnsupp: '',
      createTicket: false,
      createAccount: false,
      ticketsList: false,
      loading: true
    }
  },
  watch: {
    timeToChangeOverlay() {
      this.createTicket = false;
      this.createAccount = false;
      this.ticketsList = false;
    }

  },
  methods: {
    showTicketForm(){
      this.createTicket = this.createTicket ? false : true;
    },
    showAccountForm(){
      this.createAccount = this.createAccount ? false : true;
    },
    showTicketsList(){
      this.ticketsList = this.ticketsList ? false : true;
    }
  },
  computed: {
    ...mapState ({
      temp: state => state.temp,
      isContactInfo: state => state.isContactInfo,
      isFormAddData: state => state.isFormAddData,
      contactInfo: state => state.contact.contactInfo,
      timeToChangeOverlay: state => state.timeToChangeOverlay,
      timeToChangeStatus: state => state.timeToChangeStatus,
    }),

  }
}
</script>
