// eslint-disable-next-line import/prefer-default-export
export const clientTypes = [
  {
    value: 3, text: 'Cliente',
  },
  {
    value: 2, text: 'Aliado',
  },
  {
    value: 1, text: 'Distribuidora',
  },
]
